import roleKeys from '../../utils/accessControl/roleKeys';

export default (path) => {
  const {
    agreement, user, partner, order,
    orderContent, adminCatalog, voucherTypes,
    thirdParty, mainPage, termsOfService,
    codeProduct, codeProducts, voucherBatches,
    maintainanceDashboard,
  } = roleKeys;
  const splitRoute = path.split('/');
  const componentName = splitRoute[1];

  switch (componentName) {
    case ('agreements'):
      return agreement.viewList;
    case ('agreement'):
      if (splitRoute[2] === 'create' || splitRoute.length === 2) {
        return agreement.create;
      }
      return agreement.viewDetail;
    case ('users'):
      return user.view;
    case ('user'):
      return typeof splitRoute[2] === 'string' ? user.viewDetail : user.create;
    case ('partners'):
      return partner.viewList;
    case ('partner'):
      return typeof splitRoute[2] === 'string' ? partner.viewDetail : partner.create;
    case ('orders'):
      return order.viewList;
    case ('order'):
      return typeof splitRoute[2] === 'string' ? order.viewDetail : order.create;
    case ('add-lines'):
      return orderContent.addLineItem;
    case ('thirdPartyCatalog'):
      return thirdParty.viewCatalog;
    case ('voucher'):
      return adminCatalog.viewDetail;
    case ('vouchertypes'):
      return voucherTypes.viewList;
    case ('codeproducts'):
      return codeProducts.viewList;
    case ('vouchertype'):
      return voucherTypes.viewDetail;
    case ('tos'):
      return termsOfService.canView;
    case ('canceledtos'):
      return termsOfService.viewCanceled;
    case ('needpgpsetup'):
      return user.canSetupPgpKeys;
    case ('cart'):
      return thirdParty.canSeeCart;
    case ('orderhistory'):
      return thirdParty.canSeeOrderHistory;
    case ('ordersuccess'):
      return thirdParty.canSeeOrderHistorySuccess;
    case ('codeproduct'):
      return codeProduct.viewDetail;
    case ('voucherbatches'):
      return voucherBatches.viewList;
    case ('voucherbatch'):
      return voucherBatches.viewDetail;
    case ('maintainance'):
      return maintainanceDashboard.viewPage;
    case (''):
      return mainPage.componentName;
    default:
      return '/';
  }
};
