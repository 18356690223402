import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { Prompt, withRouter } from 'react-router-dom';
import {
  Container,
  Layout,
  Flex,
} from '@partner-global-ui/components';
import './Partner.scss';
import PartnerInfoComponent from './PartnerInfo';
import PartnerDetailAgreementComponent from './partnerDetailAgreements/PartnerDetailAgreements';
import PartnerDetailUsersComponent from './partnerDetailUsers/PartnerDetailUsers';
import PartnerDetailSFTPComponent from './partnerDetailSFTP/PartnerDetailSFTP';
import * as partnerActions from '../../actions/partnerActions';
import hasPermission from '../../utils/accessControl/hasPermission';
import roleKeys from '../../utils/accessControl/roleKeys';
import userIsPartnerRequesterUtils from '../../utils/userIsPartnerRequester.utils';
import * as types from '../../actions/actionTypes';
import PartnerTypes from '../../constants/integratorPartnerTypes.constants';
import NotFoundError from '../error/NotFound';

export class PartnerPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      unsaved: false,
      initialLoad: true,
      unauthorized: false,
    };
    this.savePartner = this.savePartner.bind(this);
  }

  componentDidMount() {
    const {
      match, partner, actions, history,
    } = this.props;

    const { newPartner, loadPartner } = actions;
    if (typeof match.params.id !== 'undefined') {
      loadPartner(match.params.id)
        .then((res) => {
          if (typeof res !== 'undefined' && res.ok && typeof partner === 'undefined') {
            history.push('/partner');
          }
        })
        .catch(() => {
          this.setState({
            unauthorized: true,
          });
        });
    } else {
      newPartner();
    }
  }

  // eslint-disable-next-line camelcase, react/sort-comp
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { partner } = this.props;
    if (nextProps.partner !== partner) {
      this.checkIfReadyToSave(nextProps.partner);
    }
  }

  componentWillUnmount() {
    const { unsaved } = this.state;
    const { actions, clearAgreements } = this.props;
    const { newPartner } = actions;
    clearAgreements();
    if (unsaved) {
      newPartner();
    }
  }

  checkIfReadyToSave = (partner) => {
    const { initialLoad } = this.state;
    if (initialLoad) {
      this.setState({
        initialLoad: false,
        unsaved: false,
      });
      return false;
    }

    const readyToSave = (!!partner.name
      && !!partner.status
      && (partner.name !== partner.initialName || partner.status !== partner.initialStatus)
    );

    this.setState({
      unsaved: readyToSave,
    });

    return true;
  }

  savePartner() {
    const {
      partner, match, actions, history,
    } = this.props;
    const { savePartner } = actions;
    const partnerExists = typeof match.params.id !== 'undefined';
    savePartner(partner, partnerExists).then((res) => {
      // order version mismatch or duplicate name
      if (res.statusCode === 412 || res.statusCode === 400) {
        return;
      }

      this.setState({
        unsaved: false,
        initialLoad: true,
      }, () => {
        history.push('/partners');
      });
    });
  }

  renderNav() {
    return (
      <div name="partnerNav" className="partner-nav">
        <Container>
          <Layout>
            <Flex
              id="partnerLabel"
              className="title"
              colSpan="10"
            >
              {i18next.t('msg_codes_accountType_partner')}
            </Flex>
          </Layout>
        </Container>
      </div>
    );
  }

  render() {
    const { unsaved, unauthorized } = this.state;
    const { partner, partnerSftps, match } = this.props;

    if (unauthorized) {
      return (<NotFoundError />);
    }

    return (
      <div name="partnerGrid" className="partner">
        {this.renderNav()}
        <Prompt
          name="partnerPrompt"
          when={unsaved}
          message="You have unsaved information, are you sure you want to leave this page?"
        />
        <Container className="partner-page">
          <Layout name="partnerInfoComponentRow" className="partner-info">
            <PartnerInfoComponent
              name="partnerInfoComponent"
              {...this.props}
              partner={partner}
            />
          </Layout>
          { typeof match.params.id !== 'undefined'
            && (
              <>
                <Layout name="partnerUsersRow" className="partner-users">
                  <PartnerDetailUsersComponent
                    name="partnerDetailUsersComponent"
                    {...this.props}
                    partner={partner}
                  />
                </Layout>
                <Layout name="partnerAgreementRow" className="partner-agreements">
                  <PartnerDetailAgreementComponent
                    name="partnerDetailAgreementComponent"
                    {...this.props}
                    partner={partner.partner}
                  />
                </Layout>
                {partner.partnerType !== PartnerTypes.THIRD_PARTY && (
                  <Layout name="partnerSFTPRow" className="partner-sftps">
                    <PartnerDetailSFTPComponent
                      name="PartnerDetailSFTPComponent"
                      {...this.props}
                      partner={partner}
                      sftpList={partnerSftps}
                    />
                  </Layout>
                )}
              </>
            )
          }
        </Container>
      </div>
    );
  }
}

PartnerPage.propTypes = {
  partner: PropTypes.object,
  partnerSftps: PropTypes.array,
  actions: PropTypes.objectOf(PropTypes.func),
  canEdit: PropTypes.bool,
  clearAgreements: PropTypes.func,
};

PartnerPage.defaultProps = {
  partner: {},
  partnerSftps: [],
  actions: {},
  canEdit: false,
  clearAgreements: () => {},
};

export function mapStateToProps(state) {
  const canEdit = !userIsPartnerRequesterUtils(state.user)
  && hasPermission(state, roleKeys.partner.create);

  return {
    canEdit,
    partner: state.partner.partner,
    partnerSftps: state.partner.partnerSftpMapList,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(partnerActions, dispatch),
    // remove in 3.1
    clearAgreements: () => { dispatch({ type: types.CLEAR_AGREEMENTS }); },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PartnerPage));
