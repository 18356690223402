import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function agreementsPageReducer(state = initialState.agreementsPage, action) {
  const { agreementsPage, filterType, filterItems } = action;
  switch (action.type) {
    case types.SET_AGREEMENTS_PAGE:
      return { ...state, ...agreementsPage };
    case types.SET_AGREEMENTS_FILTER:
      return {
        ...state,
        filters: { ...state.filters, [filterType]: filterItems },
        number: 0,
        isFiltering: true,
      };
    case types.RESET_AGREEMENTS_PAGE_FILTERING:
      return {
        ...state,
        filters: initialState.agreementsPage.filters,
        number: 0,
        isFiltering: false,
      };
    default:
      return state;
  }
}
