import React from 'react';
import {
  Collapsible,
  DataTable,
  TableHeader,
  TableHeaderCell,
  NoResults,
} from '@partner-global-ui/components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './SkuInfoSection.scss';
import { SkuInfoRow } from './SkuInfoRow';

const SkuInfoSection = (props) => {
  const { t } = useTranslation();
  const {
    canAddSku,
    disableAddSkuButton,
    skus,
    onNewSkuClick,
    onDeleteSku,
    disableVoucherType,
    loadingVoucherType,
    tableRef,
  } = props;
  const canAddAndRemoveSku = canAddSku && !disableVoucherType;
  const noSkus = (!skus || skus.length === 0);

  const noResultsTitle = noSkus && t('msg_codes_no_skus_zero');

  const noResultsMessage = noSkus && (
    canAddAndRemoveSku && !disableAddSkuButton
      ? t('msg_codes_add_skus_info', { msg_codes_add_skus: t('msg_codes_add_skus'), interpolation: { prefix: '"$$', suffix: '$$"' } })
      : ''
  );

  const deleteSku = (skuId) => {
    onDeleteSku(skuId);
  };

  const renderTableRows = () => (skus.map(sku => (
    <SkuInfoRow
      sku={sku}
      deleteSku={deleteSku}
      key={sku.skuId}
      canRemove={canAddAndRemoveSku}
      tableRef={tableRef}
    />
  )));

  const renderHeaderCells = () => {
    return (
      <TableHeader id="table-header">
        <TableHeaderCell
          id="skuId"
          key="skuId"
          value="skuId"
          className="skuId"
          hasExpandable
        >
          {t('msg_codes_orderList_skuid')}
        </TableHeaderCell>
        <TableHeaderCell
          id="country"
          key="country"
          value="country"
          className="country"
        >
          {t('msg_codes_agreements_country')}
        </TableHeaderCell>
        <TableHeaderCell
          id="start-date"
          key="start-date"
          value="start-date"
          className="start-date"
        >
          {t('msg_codes_orderDetails_startDate')}
        </TableHeaderCell>
        <TableHeaderCell
          id="end-date"
          key="end-date"
          value="end-date"
          className="end-date"
        >
          {t('msg_codes_orderDetails_endDate')}
        </TableHeaderCell>
        <TableHeaderCell
          id="status"
          key="status"
          value="status"
          className="status"
        >
          {t('msg_codes_agreements_agreementStatus')}
        </TableHeaderCell>
        <TableHeaderCell
          id="last"
          key="last"
          value="last"
          className="last"
        >
          {t('')}
        </TableHeaderCell>
      </TableHeader>
    );
  };

  return (
    <div
      id="sku-info-section"
      className="sku-info-section"
      data-testid="sku-info-section"
    >
      <Collapsible
        defaultIsOpen
        loading={loadingVoucherType}
        heading={t('msg_codes_sku_details')}
        id="sku-info-collapsible"
        rightContent={
          canAddAndRemoveSku && !disableAddSkuButton
          && [{ actionCallback: onNewSkuClick, actionText: t('msg_codes_add_skus') }]
        }
      >
        <DataTable
          id="sku-info-table"
          data-testid="sku-info-table"
          columnTemplate="repeat(6, 1fr)"
        >
          {renderHeaderCells()}
          {(skus && skus.length > 0)
            ? renderTableRows()
            : (
              <NoResults
                id="no-results"
                title={noResultsTitle}
                message={noResultsMessage}
              />
            )
          }
        </DataTable>
      </Collapsible>
    </div>
  );
};

SkuInfoSection.propTypes = {
  canAddSku: PropTypes.bool,
  disableAddSkuButton: PropTypes.bool,
  skus: PropTypes.array,
  onDeleteSku: PropTypes.func,
  onNewSkuClick: PropTypes.func,
  disableVoucherType: PropTypes.bool,
  loadingVoucherType: PropTypes.bool,
  tableRef: PropTypes.object,
};

SkuInfoSection.defaultProps = {
  canAddSku: false,
  disableAddSkuButton: false,
  skus: [],
  onDeleteSku: () => null,
  onNewSkuClick: () => null,
  disableVoucherType: false,
  loadingVoucherType: false,
  tableRef: {},
};

export default SkuInfoSection;
