import il8n from 'i18next';
import axios from 'axios';
import * as types from './actionTypes';
import { autoHide, timeout } from '../constants/notifications.constant';
import { currencyCodes } from '../constants/currencies.constants';
import * as lineCacheActions from './orderLineCacheActions';
import { getActiveAgreementRegions } from './ordersActions';
// import { getProduct } from './codeProductActions';
import {
  pickNewOrder,
  pickUpdateOrder,
  pickUpdateGRCOrder,
} from '../helpers/pickOrder';
import { mapErrorStrings } from '../components/order/permittedUses/CodeProductSwapModal';

export function beginSaveOrder() {
  return { type: types.BEGIN_SAVE_ORDER };
}

export function beginLoadOrder() {
  return { type: types.BEGIN_LOAD_ORDER };
}

export function versionMismatch() {
  return { type: types.VERSION_MISMATCH };
}

export function saveOrderSuccess(order) {
  return { type: types.SAVE_ORDER_SUCCESS, order };
}

export function saveOrderFailure() {
  return { type: types.SAVE_ORDER_FAILURE };
}

export function approveOrderSuccess(order) {
  return { type: types.APPROVE_ORDER_SUCCESS, order };
}

export function rejectOrderSuccess(order) {
  return { type: types.REJECT_ORDER_SUCCESS, order };
}

export function requestAmendmentSuccess(order) {
  return { type: types.REQUEST_AMENDMENT_SUCCESS, order };
}

export function loadOrderSuccess(order) {
  return { type: types.LOAD_ORDER_SUCCESS, order };
}

export function saveInitialOrder(order) {
  return { type: types.SAVE_INITIAL_ORDER, order };
}

export function setOrderAuditFields(orderNumber) {
  return ((dispatch) => {
    return axios.get(`/venom/api/audit/details?entity=ORDER&id=${orderNumber}`)
      .then(response => response.data)
      .then((auditFields) => {
        dispatch({ type: types.SET_ORDER_AUDIT_FIELDS, auditFields });
      });
  });
}

export function loadOrderFail() {
  return { type: types.FINISH_LOAD_ORDER };
}

export function beginSubmitOrder() {
  return { type: types.BEGIN_SUBMIT_ORDER };
}

export function beginRejectOrder() {
  return { type: types.BEGIN_REJECT_ORDER };
}

export function beginRequestAmendment() {
  return { type: types.BEGIN_REQUEST_AMENDMENT };
}

export function beginApproveOrder() {
  return { type: types.BEGIN_APPROVE_ORDER };
}

export function changePropSuccess(name, value) {
  if ((name === 'partnerSftpId' || name === 'cardManufacturePriceCurrency') && value === '') {
    return { type: types.CHANGE_PROP_SUCCESS, name, value: null };
  }

  return { type: types.CHANGE_PROP_SUCCESS, name, value };
}

export function changePropOnLineSuccess(line, name, value) {
  return {
    type: types.CHANGE_PROP_ON_LINE_SUCCESS, line, name, value,
  };
}

export function changeShareWithPartner(fileId, value) {
  return {
    type: types.CHANGE_SHARE_WITH_PARTNER, fileId, value,
  };
}

export function submitOrderSuccess(order) {
  return { type: types.SUBMIT_ORDER_SUCCESS, order };
}

export function cancelOrderSuccess(orderNumber) {
  return { type: types.CANCEL_ORDER_SUCCESS, orderNumber };
}

export function getPartnersSuccess(partners) {
  return { type: types.GET_PARTNERS_SUCCESS, partners };
}

export function addItemsToOrderSuccess(order, lines) {
  return { type: types.ADD_ITEMS_SUCCESS, order, lines };
}

export function newOrderSuccess() {
  return { type: types.NEW_ORDER_SUCCESS };
}

export function addOrderDocument(orderId, order) {
  return { type: types.ADD_ORDER_DOCUMENT, orderId, order };
}

export function removeOrderDocument(orderId, attachmentId) {
  return { type: types.REMOVE_ORDER_DOCUMENT, orderId, attachmentId };
}

export function getActiveAgreementVersionSuccess(activeAgreementVersion, callbackGateways) {
  return {
    type: types.GET_ACTIVE_AGREEMENT_VERSION_SUCCESS,
    activeAgreementVersion,
    callbackGateways,
  };
}

export function getActiveAgreementVersionError() {
  return { type: types.GET_ACTIVE_AGREEMENT_VERSION_ERROR };
}

export function removeActiveAgreementVersion() {
  return { type: types.REMOVE_ACTIVE_AGREEMENT_VERSION };
}

export function getActiveAgreementVersionForOrder(partnerId, regionCode) {
  const request = `/venom/api/agreements/activeFinalVersion?partnerId=${partnerId}&region=${regionCode}`;
  return (dispatch, getState) => axios.get(request)
    .then(response => response.data)
    .then((agreementVersion) => {
      dispatch(
        getActiveAgreementVersionSuccess(
          agreementVersion,
          getState().callbackGateways,
        ),
      );
    })
    .catch(() => {
      dispatch(getActiveAgreementVersionError());
    });
}

export function loadOrder(orderNumber) {
  let version;
  return ((dispatch, getState) => {
    dispatch(beginLoadOrder());
    return axios.get(`/venom/api/orders/${orderNumber}`)
      .then((response) => {
        const order = response.data;
        version = response.headers.ETag;
        dispatch(setOrderAuditFields(orderNumber));
        if (order.partnerSftpId === null) {
          // Map null partnerSftpId to ' ' to match the Deliver SFTP To input's default
          //  value. By not using null for the default value, it allows for the save
          //  button to recognize when the field changes to or from the default value.
          order.partnerSftpId = ' ';
        }
        const { removedOrderLines } = getState();
        const newDeletedOrderLines = lineCacheActions
          .filterSeenDeletedOrderLines(order.deletedOrderLines, removedOrderLines);
        order.orderLines = [...order.orderLines, ...newDeletedOrderLines];
        order.attachments = order.attachments ? order.attachments : [];
        const { region, codeFeeCurrency } = order;
        const isAlreadySelected = region && currencyCodes[region].find(isCurrency => isCurrency);
        if (typeof codeFeeCurrency === 'undefined' && isAlreadySelected) {
          order.codeFeeCurrency = isAlreadySelected.label;
        }
        dispatch(loadOrderSuccess(Object.assign({}, order, { version })));
        getActiveAgreementVersionForOrder(order.partnerId, order.region)(dispatch, getState);
        getActiveAgreementRegions(order.partnerId)(dispatch);
        dispatch(saveInitialOrder(order));
        return { ...order, ok: true };
      })
      .catch((error) => {
        dispatch(loadOrderFail());
        return { ...error, ok: false };
      });
  });
}

export function saveOrder(order, updatedOrderValues = {}, notificationContext) {
  const { newOrder: isNewOrder, ...rest } = order;
  // const orderToSave = isNewOrder ? pickNewOrder(rest) : pickUpdateOrder(rest);
  const orderToSave = {
    [true]: () => pickUpdateOrder(rest),
    [order.agreementType === 'THIRD_PARTY_RATECARD']: () => pickUpdateGRCOrder(rest, updatedOrderValues),
    [isNewOrder]: () => pickNewOrder(rest),
  }.true();

  // send required agreementType during save
  // default to AGENCY for created orders
  const agreementType = order.agreementType || 'AGENCY';

  return ((dispatch, getState) => {
    dispatch(beginSaveOrder());
    let version = null;
    return axios.post(
      '/venom/api/orders',
      {
        agreementType,
        ...orderToSave,
      },
      {
        'If-Match': order.version,
      },
    ).then((response) => {
      version = response.headers.ETag;
      return response.data;
    }).then(async (orderResponse) => {
      const { removedOrderLines } = getState();
      const newDeletedOrderLines = lineCacheActions
        .filterSeenDeletedOrderLines(orderResponse.deletedOrderLines, removedOrderLines);
      orderResponse.orderLines = [...orderResponse.orderLines, ...newDeletedOrderLines];
      const updatedOrder = { ...orderResponse, version };
      dispatch(setOrderAuditFields(order.orderNumber));
      dispatch(saveOrderSuccess(updatedOrder));
      dispatch(saveInitialOrder(updatedOrder));
      await loadOrder(order.orderNumber)(dispatch, getState);
      return updatedOrder;
    }).catch((error) => {
      if (error.response && error.response.status === 412) {
        dispatch(versionMismatch());
      }
      if (error.response.data.detailMessages.length >= 1) {
        error.response.data.detailMessages.forEach((message) => {
          notificationContext.dispatch({
            type: 'add',
            payload: {
              status: 'error',
              autoHide,
              timeout,
              message,
              testId: 'error',
            },
          });
        });
      }
      dispatch(saveOrderFailure());
      return Promise.reject();
    });
  });
}

export function getPartners(region) {
  return (dispatch => axios.get(`/venom/api/partners/${region}`)
    .then(response => response.data)
    .then((partners) => {
      dispatch(getPartnersSuccess(partners));
    })
    .catch(() => {
      // TODO: error handling
      // dispatch(vouchersHasErrored(true))
    })
  );
}


export async function downloadDocumentFile(orderId, fileId) {
  return axios.post(`/venom/api/orders/${orderId}/documents/${fileId}/download`).then((response) => {
    const orderDocumentWithFile = response.data;
    const { fileUrl } = orderDocumentWithFile;
    if (orderDocumentWithFile) {
      window.open(fileUrl);
    }
    return fileUrl;
  });
}

export function cancelOrder(orderNumber) {
  return dispatch => axios.delete(`/venom/api/orders/${orderNumber}`)
    .then(() => {
      dispatch(cancelOrderSuccess(orderNumber));
    }).catch(() => { });
}

export function submitOrder(orderNumber) {
  return ((dispatch) => {
    dispatch(beginSubmitOrder());
    return axios.put(`/venom/api/submit/${orderNumber}`)
      .then(response => response.data)
      .then((order) => {
        dispatch(submitOrderSuccess(order));
      })
      .catch(() => {
        // TODO: error handling
        // dispatch(vouchersHasErrored(true))
      });
  });
}

export function approveOrder({
  orderNumber,
  applyFeeWaiver = false,
  feeWaiverReason,
  codeFee,
  codeFeeCurrency,
  orderLines = [],
}) {
  return ((dispatch) => {
    dispatch(beginApproveOrder());
    const useCount = orderLines.reduce((acc, line) => {
      if (Number(line.useCount) >= 1 && Number(line.useCount) <= 1000000) {
        acc[line.orderLineId] = Number(line.useCount);
      }
      return acc;
    }, {});

    const body = {
      applyFeeWaiver,
      feeWaiverReason,
      codeFee,
      codeFeeCurrency,
      useCount,
    };

    return axios.post(`/venom/api/approve/${orderNumber}`, {
      ...body,
    })
      .then(response => response.data)
      .then((order) => {
        dispatch(approveOrderSuccess(order));
        return order;
      })
      .catch(() => {
        // TODO: error handling
        // dispatch(vouchersHasErrored(true))
      });
  });
}

export function rejectOrder(orderNumber, comment, applyFeeWaiver = false) {
  return ((dispatch) => {
    dispatch(beginRejectOrder());
    return axios.put(`/venom/api/reject/${orderNumber}`, {
      comment,
      applyFeeWaiver,
    }).then(response => response.data)
      .then((order) => {
        dispatch(rejectOrderSuccess(order));
      })
      .catch((error) => {
        return error;
      });
  });
}

export function amendOrder(orderNumber, comment, applyFeeWaiver = false) {
  return ((dispatch) => {
    dispatch(beginRequestAmendment());
    return axios.put(`/venom/api/amend/${orderNumber}`, {
      comment,
      applyFeeWaiver,
    }).then(response => response.data)
      .then((order) => {
        dispatch(requestAmendmentSuccess(order));
      })
      .catch((error) => {
        // TODO: error handling
        // dispatch(vouchersHasErrored(true))
        return error;
      });
  });
}

export function addItemsToOrder(order, lines) {
  return (dispatch => dispatch(addItemsToOrderSuccess(order, lines)));
}

export function changeProp(name, value) {
  return (dispatch => dispatch(changePropSuccess(name, value)));
}

export function setPartnerForOrder(partner) {
  return { type: types.SET_PARTNER_FOR_ORDER, partner };
}

export function changePropOnLine(line, name, value) {
  return (dispatch => dispatch(changePropOnLineSuccess(line, name, value)));
}

export function newOrder() {
  return (dispatch => dispatch(newOrderSuccess()));
}

export async function submitOrderFile(
  dispatch,
  notificationContext,
  orderNumber,
  revision,
  documentFile,
) {
  const url = `/venom/api/orders/${orderNumber}/documents`;

  const requestBody = new FormData();
  if (documentFile) {
    const blob = await fetch(documentFile.file).then(r => r.blob());
    const file = new File([blob], documentFile.name, { type: documentFile.type });
    requestBody.append('file', file, documentFile.name);
  }
  const eTag = `"${revision}"`;

  try {
    const uploadResponse = await axios.post(url, requestBody, { 'if-match': eTag }).then(
      (response) => {
        notificationContext.dispatch({
          type: 'add',
          payload: {
            status: 'success',
            autoHide,
            timeout,
            message: il8n.t('msg_codes_orderDetailsAdmin_fileUploaded_success_toast'),
          },
        });
        dispatch(addOrderDocument(orderNumber, response.data));
        return response;
      },
      () => {
        notificationContext.dispatch({
          type: 'add',
          payload: {
            status: 'error',
            autoHide,
            timeout,
            message: il8n.t('msg_codes_orderDetailsAdmin_fileUploaded_fail_toast'),
          },
        });
      },
    );
    return uploadResponse;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function removeOrderFile(
  dispatch,
  notificationContext,
  orderNumber,
  attachmentId,
) {
  const url = `/venom/api/orders/${orderNumber}/documents/${attachmentId}`;
  try {
    const removeResponse = await axios.delete(url)
      .then(
        () => {
          notificationContext.dispatch({
            type: 'add',
            payload: {
              status: 'success',
              autoHide,
              timeout,
              message: il8n.t('msg_codes_orderDetailsAdmin_fileRemoved_success_toast'),
            },
          });
          dispatch(removeOrderDocument(orderNumber, attachmentId));
        },
        () => {
          notificationContext.dispatch({
            type: 'add',
            payload: {
              status: 'error',
              autoHide,
              timeout,
              message: il8n.t('msg_codes_orderDetailsAdmin_fileRemoved_fail_toast'),
            },
          });
        },
      );
    return removeResponse;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export function SetVisibilityOfFile(orderNumber, attachmentId, shareWithPartner) {
  const url = `venom/api/orders/${orderNumber}/documents/${attachmentId}`;
  const body = { shareWithPartner };
  const successMessage = shareWithPartner ? 'msg_codes_document_shared' : 'msg_codes_document_no_longer_shared';
  return (dispatch, notificationContext) => {
    return axios.put(url, body)
      .then((response) => {
        const order = { ...response.data, version: response.headers.ETag };
        notificationContext.dispatch({
          type: 'add',
          payload: {
            status: 'success',
            autoHide,
            timeout,
            message: il8n.t(successMessage),
          },
        });
        dispatch(saveOrderSuccess(order));
      })
      .catch(() => {
        notificationContext.dispatch({
          type: 'add',
          payload: {
            status: 'error',
            autoHide,
            timeout,
            message: il8n.t('msg_codes_codes_tooltip_somethingWentWrong'),
          },
        });
      });
  };
}

export function setOrderDocumentsScanStatus(orderNumber) {
  return ((dispatch) => {
    return axios.get(`/venom/api/orders/${orderNumber}/documentStatus`)
      .then(response => response.data)
      .then((orderDocumentsScanStatus) => {
        dispatch({ type: types.UPDATE_ORDER_DOCUMENTS_SCAN_STATUS, orderDocumentsScanStatus });
      })
      .catch(() => {
        //  TODO: error handling
      });
  });
}

// currently only works with agency orders
export function downloadOrder(orderId) {
  return (dispatch) => {
    dispatch({ type: types.START_LOADING });
    return axios.get(`/venom/api/downloadPdf/${orderId}`, { responseType: 'blob' }).then((response) => {
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

      const blobUrl = window.URL.createObjectURL(pdfBlob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('download', `order-${orderId}`);
      link.click();
      link.remove();
      URL.revokeObjectURL(blobUrl);
    }).then(() => {
      dispatch({ type: types.STOP_LOADING });
    }).catch(() => {
      dispatch({ type: types.STOP_LOADING });
    });
  };
}

export function validateCodeProductSwap(orderLineId, catalogIdOrTypeId) {
  const body = {
    orderLineId,
    codeProductIdOrTypeId: catalogIdOrTypeId,
  };
  return axios.post(
    '/venom/api/validateProductSwap',
    {
      ...body,
    },
  )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      return error;
    });
}

export function swapCodeProduct(
  orderLineId,
  catalogIdOrTypeId,
  notificationContext,
  oldCodeProductName,
  newCodeProductName,
  dispatch,
  orderNumber,
) {
  const body = {
    orderLineId,
    codeProductIdOrTypeId: catalogIdOrTypeId,
  };
  return axios.post(
    '/venom/api/performProductSwap',
    {
      ...body,
    },
  )
    .then((response) => {
      dispatch(loadOrder(orderNumber));
      notificationContext.dispatch({
        type: 'add',
        payload: {
          status: 'success',
          autoHide,
          timeout,
          message: il8n.t(
            'msg_codes_product_swap_success_toast', {
              oldCodeProductName,
              newCodeProductName,
            },
          ),
          testId: 'success',
        },
      });
      return response;
    })
    .catch((error) => {
      notificationContext.dispatch({
        type: 'add',
        payload: {
          status: 'error',
          autoHide,
          timeout,
          message: il8n.t(
            mapErrorStrings(error),
            {
              oldCodeProductName,
              newCodeProductName,
            },
          ),
          testId: 'error',
        },
      });
      return error;
    });
}
