import React, {
  useState,
  useEffect,
  useContext,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Container,
  Flex,
  NotificationContext,
  Select,
  TextInput,
  ProgressIndicator,
  TextArea,
} from '@partner-global-ui/components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './voucherBatchDetailPage.scss';
import moment from 'moment';
import _ from 'lodash';

import DateTime from '../common/DateTime/DateTime';
import * as actions from '../../actions/voucherBatchDetailActions';
import {
  loadVoucherType,
  resetVoucherType,
} from '../../actions/voucherTypeDetailActions';
import { isVoucherBatchValid } from '../../utils/isValidVoucherBatchForm';
import DateAndCountrySection from './DateAndCountrySection';
import VoucherBatchInfoSection from './VoucherBatchInfoSection';
import AddVoucherTypeId from '../common/AddVoucherTypeId/AddVoucherTypeId';
import VoucherBatchAuditHistory from './VoucherBatchAuditHistory';
import VoucherBatchHeader from './VoucherBatchHeader';
import hasPermission from '../../utils/accessControl/hasPermission';
import roles from '../../utils/accessControl/roleKeys';
import * as voucherBatchDistributors from '../../mocks/voucherBatchDistributors';
import { timeout } from '../../constants/notifications.constant';


const VoucherBatchDetailPage = ({ ...props }) => {
  const { t } = useTranslation();
  const notificationCont = useContext(NotificationContext);
  const { history, location: { voucherTypeId }, match: { params } } = props;
  const canEditVoucherBatch = useSelector(state => hasPermission(state, roles.voucherBatches.edit));
  const voucherBatch = useSelector(state => state.voucherBatch);
  const voucherType = useSelector(state => state.voucherType);
  const loadingVoucherBatch = useSelector(state => state.loadingVoucherBatch);
  const dispatch = useDispatch();
  const [isEdited, setEdited] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isGMT, setIsGMT] = useState(true);
  const [addVoucherTypeIdOpened, setAddVoucherTypeIdOpened] = useState(false);
  const [isNew, setIsNew] = useState(_.isEmpty(voucherBatch.voucherBatchId));
  const isMoneyVoucherType = voucherType.npType === 'MONEY';
  const isProductVoucherType = voucherType.npType === 'PRODUCT';
  const hasSkus = voucherType.skus ? voucherType.skus.length : false;
  const {
    content: auditHistoryList,
  } = useSelector(state => state.voucherBatchAuditHistory);
  const createdAuditHistory = auditHistoryList.filter(audit => audit.actionType === 'Created')[0] || {};
  const createdBy = createdAuditHistory.email || voucherBatch.createdByName;
  const creationDate = createdAuditHistory.creationDate || voucherBatch.creationDate;
  const readOnlyBatch = typeof voucherBatch.partnerId === 'number';
  // The condition is added to support old batches with "wrong" distributors
  const distributors = isNew
    ? _.sortBy(voucherBatchDistributors[voucherBatch.region],
      distributor => distributor.name.toLowerCase())
    : _.sortBy(voucherBatchDistributors.FULL_LIST, distributor => distributor.name.toLowerCase());

  const getDefaultDistributorId = () => voucherBatch.region && distributors
    .find(distributor => distributor.name === voucherBatch.region).id;

  useEffect(() => {
    if (params.id === 'create') {
      dispatch(actions.loadNewVoucherBatch(voucherTypeId));
      window.scrollTo(0, 0);
    } else {
      dispatch(actions.loadVoucherBatch(notificationCont, params.id));
    }
    return () => dispatch(actions.resetVoucherBatch());
  }, []);

  const getSuccessText = () => {
    if (voucherBatch.state === 'FILE_GENERATION_IN_PROGRESS') {
      return t('msg_codes_batch_file_generated_success');
    }
    return t('msg_codes_batch_created_success');
  };

  useEffect(() => {
    let interval;
    if (voucherBatch.voucherBatchId && !interval
      && (voucherBatch.state === 'FILE_GENERATION_IN_PROGRESS' || voucherBatch.state === 'CREATION_IN_PROGRESS')) {
      interval = setInterval(
        () => actions.pollVoucherBatch(
          notificationCont, voucherBatch.voucherBatchId, getSuccessText(),
        )(dispatch),
        timeout,
      );
    }
    return () => interval ? clearInterval(interval) : null;
  }, [voucherBatch.state]);

  useEffect(() => {
    if (voucherBatch.voucherTypeId) {
      loadVoucherType(voucherBatch.voucherTypeId, notificationCont)(dispatch);
    }
    return () => dispatch(resetVoucherType());
  }, [voucherBatch.voucherTypeId]);

  useEffect(() => {
    setIsNew(_.isEmpty(voucherBatch.voucherBatchId));
  }, [voucherBatch.voucherBatchId]);

  useEffect(() => {
    if (!voucherBatch.region) {
      dispatch(actions.changeVoucherBatchProp('region', voucherType.npRegion));
    }
  }, [voucherBatch.region]);

  useEffect(() => {
    if (!voucherBatch.voucherProductTypeCode) {
      const productTypeCode = isMoneyVoucherType ? 'MONEY' : null;
      dispatch(actions.changeVoucherBatchProp('voucherProductTypeCode', productTypeCode));
    }
  }, [voucherBatch.voucherProductTypeCode]);

  useEffect(() => {
    if (!voucherBatch.distributorId) {
      dispatch(actions.changeVoucherBatchProp('distributorId', getDefaultDistributorId()));
    }
  }, [voucherBatch.region]);

  const openAddVoucherTypeId = () => {
    setAddVoucherTypeIdOpened(true);
  };

  const closeAddVoucherTypeId = () => {
    setAddVoucherTypeIdOpened(false);
  };

  const handleChange = (event) => {
    const { target: { name, value } } = event;
    dispatch(actions.changeVoucherBatchProp(name, value));
    setEdited(true);
  };

  const handleTextChange = (event) => {
    const { target: { name, value } } = event;
    dispatch(actions.changeVoucherBatchProp(name, value));
    setEdited(true);
  };

  const handleSelectChange = (event) => {
    const { target: { name, value: { value } } } = event;
    dispatch(actions.changeVoucherBatchProp(name, value));
    setEdited(true);
  };

  const onAddTypeId = (newTypeId) => {
    handleChange({
      target: {
        name: 'voucherTypeId',
        value: newTypeId,
      },
    });
  };

  const handleDateTimeChange = (e, fieldName) => {
    let dateValue;

    // Start Date
    if (fieldName === 'startDate') {
      const { target: { value } } = e;

      const unformattedDate = value.slice(0, 10);
      const isDateValid = moment(unformattedDate, 'MM/DD/YYYY', true).isValid();

      const date = isDateValid ? moment(unformattedDate).format('YYYY-MM-DD') : '';
      const time = _.isEmpty(voucherBatch.startDate) ? '00:00' : voucherBatch.startDate.slice(11, 16);
      dateValue = date ? `${date}T${time}:00.000Z` : '';
    }

    // Start Time
    if (fieldName === 'startDateTime') {
      const date = moment(voucherBatch.startDate.slice(0, 10)).format('YYYY-MM-DD');
      const time = e;
      dateValue = `${date}T${time}:00.000Z`;
    }

    // End Date
    if (fieldName === 'endDate') {
      const { target: { value } } = e;

      const unformattedDate = value.slice(0, 10);
      const isDateValid = moment(unformattedDate, 'MM/DD/YYYY', true).isValid();

      const date = isDateValid ? moment(unformattedDate).format('YYYY-MM-DD') : '';
      const time = _.isEmpty(voucherBatch.endDate) ? '00:00' : voucherBatch.endDate.slice(11, 16);
      dateValue = date ? `${date}T${time}:00.000Z` : '';
    }

    // End Time
    if (fieldName === 'endDateTime') {
      const date = moment(voucherBatch.endDate.slice(0, 10)).format('YYYY-MM-DD');
      const time = e;
      dateValue = `${date}T${time}:00.000Z`;
    }

    dispatch(actions.changeVoucherBatchProp(fieldName.replace('Time', ''), dateValue));
    setEdited(true);
  };

  const handleCancelClick = () => {
    history.push('/voucherbatches');
  };

  const handleDateRadioChange = (e) => {
    setIsGMT(e.target.name === 'gmt');

    if (e.target.name === 'local') {
      dispatch(
        actions.changeVoucherBatchProp('startDate', moment.utc(voucherBatch.startDate).local().format()),
      );

      dispatch(
        actions.changeVoucherBatchProp('endDate', moment.utc(voucherBatch.endDate).local().format()),
      );
    }

    if (e.target.name === 'gmt') {
      dispatch(
        actions.changeVoucherBatchProp('startDate', moment.utc(voucherBatch.startDate).utc().format()),
      );

      dispatch(
        actions.changeVoucherBatchProp('endDate', moment.utc(voucherBatch.endDate).utc().format()),
      );
    }
  };

  const handleVoucherBatchSave = () => {
    const navigate = id => params.id === 'create' ? history.push(`/voucherbatch/${id}`) : {};

    let voucherBatchWithDates = {
      ...voucherBatch,
    };

    if (!isGMT) {
      const sDate = `${voucherBatch.startDate.slice(0, -6)}.000Z`;
      const eDate = `${voucherBatch.endDate.slice(0, -6)}.000Z`;

      voucherBatchWithDates = {
        ...voucherBatch,
        startDate: sDate,
        endDate: eDate,
      };
    }
    setIsSaving(true);
    actions.saveVoucherBatch({
      notificationCont, voucherBatch: voucherBatchWithDates, navigate,
    })(dispatch)
      .then(() => {
        setEdited(false);
      }).finally(() => {
        setIsSaving(false);
      });
  };

  const handleBatchActions = (action, actionParams) => {
    switch (action) {
      case 'activate':
        actions.activateVoucherBatch(notificationCont, voucherBatch.voucherBatchId)(dispatch);
        break;
      case 'generate':
        actions.generateVoucherBatchFile(notificationCont, voucherBatch.voucherBatchId)(dispatch);
        break;
      case 'download':
        actions.downloadVoucherBatch(notificationCont, voucherBatch.voucherBatchId)(dispatch);
        break;
      case 'deactivate':
        actions.deactivateVoucherBatch(
          notificationCont, voucherBatch.voucherBatchId, actionParams,
        )(dispatch);
        break;
      default:
        break;
    }
  };

  const renderTextAreaWithMaxCharLimit = (
    label, required, value, name, disabled, maxLimit, isNumber = false,
  ) => {
    return (
      <div className={['voucher-batch-form-group', 'voucher-batch-details-form-field'].join(' ')}>
        <TextArea
          name={name}
          id={`voucher-batch-detail-text-area-${name}`}
          value={value}
          disabled={disabled}
          charCountMax={maxLimit}
          onChange={e => handleChange(e)}
          onKeyPress={e => isNumber && !new RegExp('^\\d+$').test(e.key) && e.preventDefault()}
          placeholder={t('msg_codes_codeProdCat_typeSomething_dropdown')}
          label={t(label)}
          required={required}
          charCountText={t('msg_codes_characters')}
          displayCount
        />
      </div>
    );
  };

  const renderSelect = (
    label,
    required,
    value,
    name,
    options,
    disabled,
    hasTooltip = false,
    tooltipText = '',
  ) => {
    const translatedOptions = options.map((option) => {
      return {
        ...option,
        label: t(option.label),
      };
    });
    const selectedOption = translatedOptions.find((option) => {
      return option.value === Number(value);
    });

    return (
      <div
        className="voucher-batch-form-group"
        data-testid={`voucher-batch-form-group-${name}`}
      >
        <Select
          name={name}
          options={translatedOptions}
          onChange={e => handleSelectChange(e)}
          value={selectedOption}
          id={`voucher-batch-detail-select-${name}`}
          disabled={disabled}
          label={t(label)}
          required={required}
          {...(hasTooltip && { tooltip: tooltipText })}
        />
      </div>
    );
  };

  const renderInput = (
    label,
    required,
    value,
    name,
    disabled,
    hasTooltip = false,
    tooltipText = '',
  ) => {
    return (
      <div
        className="voucher-batch-form-group"
        data-testid={`voucher-batch-form-group-${name}`}
      >
        <TextInput
          id={`voucher-batch-text-input-${name}`}
          label={t(label)}
          type="text"
          name={name}
          disabled={disabled}
          placeholder={t('msg_codes_codeProdCat_typeSomething_dropdown')}
          onChange={e => handleTextChange(e)}
          required={required}
          value={value}
          width={528}
          {...(hasTooltip && { tooltip: tooltipText })}
        />
      </div>
    );
  };

  const renderDate = (label, required, value, name, disabled = false) => {
    const date = value.slice(0, 10);
    const time = value.slice(11, 16);
    const disabledPastDates = name === 'endDate';

    return (
      <div
        className="voucher-batch-date-time"
        data-testid="voucher-batch-date-time"
      >
        <DateTime
          name={name}
          label={t(label)}
          date={date}
          time={time}
          required={required}
          disabled={disabled}
          handleDateTimeChange={handleDateTimeChange}
          disabledPastDates={disabledPastDates}
        />
      </div>
    );
  };

  // render this if loading
  if (loadingVoucherBatch) {
    return (
      <div data-testid="voucher-batch" className="voucher-batch">
        <Container
          id="voucher-batch-detail-page"
          className="voucher-batch-detail-page"
        >
          <Flex
            id="voucher-batch-detail-page-loading"
            className="voucher-batch-detail-page-loading"
            colSpan={12}
          >
            <ProgressIndicator id="voucher-batch-detail-progress-indicator" indeterminate />
          </Flex>
        </Container>
      </div>
    );
  }

  return (
    <div
      data-testid="voucher-batch"
      className="voucher-batch"
    >
      <Container
        className={`voucher-batch-detail-page page-container ${addVoucherTypeIdOpened ? 'add-voucher-type-id-opened' : ''}`}
        id="voucher-batch-detail-page"
        key={`voucher-batch-detail-page-${voucherBatch?.voucherTypeId || 'voucherTypeId'}`}
      >
        {!addVoucherTypeIdOpened && (
          <>
            <VoucherBatchHeader
              isEdited={isEdited}
              isVoucherBatchValid={isVoucherBatchValid}
              voucherBatch={voucherBatch}
              canEditVoucherBatch={canEditVoucherBatch}
              readOnlyBatch={readOnlyBatch}
              handleCancelClick={handleCancelClick}
              handleVoucherBatchSave={handleVoucherBatchSave}
              isSaving={isSaving}
            />
            <VoucherBatchInfoSection
              renderInput={renderInput}
              renderSelect={renderSelect}
              renderTextAreaWithMaxCharLimit={renderTextAreaWithMaxCharLimit}
              voucherBatch={voucherBatch}
              hasSkus={hasSkus}
              isNew={isNew}
              isProductVoucherType={isProductVoucherType}
              npRegion={voucherType.npRegion}
              npType={voucherType.npType}
              isMoneyVoucherType={isMoneyVoucherType}
              handleBatchActions={handleBatchActions}
              openAddVoucherTypeId={openAddVoucherTypeId}
              createdBy={createdBy}
              creationDate={creationDate}
              canEditVoucherBatch={canEditVoucherBatch}
              readOnlyBatch={readOnlyBatch}
            />
            <DateAndCountrySection
              isGMT={isGMT}
              handleDateRadioChange={handleDateRadioChange}
              renderDate={renderDate}
              startDate={voucherBatch.startDate}
              endDate={voucherBatch.endDate}
              isProductVoucherType={isProductVoucherType}
              skus={voucherType.skus}
              canEditVoucherBatch={canEditVoucherBatch && !readOnlyBatch}
            />
            <VoucherBatchAuditHistory
              voucherBatch={voucherBatch}
            />
          </>
        )}
        {addVoucherTypeIdOpened
          && (
            <AddVoucherTypeId
              onClose={closeAddVoucherTypeId}
              onAddTypeId={onAddTypeId}
              currentVoucherTypeId={voucherBatch.voucherTypeId}
              npType={voucherType.npType}
            />
          )}
      </Container>
    </div>
  );
};

VoucherBatchDetailPage.propTypes = {
  location: PropTypes.object,
};

VoucherBatchDetailPage.defaultProps = {
  location: {},
};

export default VoucherBatchDetailPage;
