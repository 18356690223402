import React, {
  useCallback,
  useContext,
} from 'react';
import { useHistory } from 'react-router-dom';
import {
  Collapsible,
  Icon,
  Layout,
  NotificationContext,
  Flex,
} from '@partner-global-ui/components';
import { isEmpty, sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import purposes from '../../mocks/voucherBatchPurposes';
import statuses from '../../mocks/voucherBatchStatuses';
import formatDate from '../../utils/formatDate';
import * as voucherBatchDistributors from '../../mocks/voucherBatchDistributors';
import DownloadSection from './DownloadSection';
import { autoHide, timeout } from '../../constants/notifications.constant';

const VoucherBatchInfoSection = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const notificationContext = useContext(NotificationContext);

  const {
    voucherBatch,
    renderSelect,
    hasSkus,
    renderInput,
    renderTextAreaWithMaxCharLimit, isNew, isProductVoucherType,
    isMoneyVoucherType, handleBatchActions, readOnlyBatch,
    openAddVoucherTypeId, createdBy, creationDate, canEditVoucherBatch,
    npRegion,
  } = props;
  const isEditable = canEditVoucherBatch && !readOnlyBatch;

  // The condition is added to support old batches with "wrong" distributors
  const distributors = isNew
    ? sortBy(voucherBatchDistributors[npRegion],
      distributor => distributor.name.toLowerCase()).map((distributor) => {
      return {
        ...distributor,
        label: distributor.name,
        value: Number(distributor.id),
      };
    })
    : sortBy(voucherBatchDistributors.FULL_LIST, distributor => distributor.name.toLowerCase())
      .map((distributor) => {
        return {
          ...distributor,
          label: distributor.name,
          value: Number(distributor.id),
        };
      });

  const copy = (event) => {
    event.stopPropagation();

    try {
      const dummyInput = document.createElement('input');
      document.body.appendChild(dummyInput);
      dummyInput.setAttribute('value', voucherBatch.voucherBatchId.trim());
      dummyInput.select();
      document.execCommand('copy');
      document.body.removeChild(dummyInput);
      notificationContext.dispatch({
        type: 'add',
        payload: {
          status: 'success',
          autoHide,
          timeout,
          message: t('msg_codes_batchid_copied_confirmation'),
          testId: 'success',
        },
      });
    } catch (error) {
      notificationContext.dispatch({
        type: 'add',
        payload: {
          status: 'error',
          autoHide,
          timeout,
          message: t('msg_codes_error_header'),
          testId: 'error',
        },
      });
      console.error(error);
    }
  };

  const getHeaderText = () => {
    if (isNew) {
      return t('msg_codes_voucher_batch_info');
    }
    return (
      <span
        data-testid="copy-icon-container"
        className="copy-icon"
        onClick={copy}
        onKeyPress={copy}
        role="button"
        tabIndex={0}
      >
        {t('msg_codes_voucher_batch_id_variable', { voucherBatchID: voucherBatch.voucherBatchId })}
        <Icon id="copy-icon">ico-copy</Icon>
      </span>
    );
  };

  const getHeaderDescriptionText = () => {
    return t('msg_codes_newOrder_createdBy', {
      name: createdBy || t('msg_codes_users_system'),
      date: `${creationDate && formatDate(creationDate)}`,
      interpolation: {
        escapeValue: false,
      },
    });
  };

  const goToVoucherTypePage = () => {
    const typeId = voucherBatch.voucherTypeId;
    history.push(`/vouchertype/${typeId}`);
  };

  const getVoucherTypeId = useCallback(() => {
    const typeId = voucherBatch.voucherTypeId;
    return (
      <span
        className="voucher-id"
        data-testid="voucher-id"
      >
        <span
          className="voucher-type-id-label"
          data-testid="voucher-type-id-label"
        >
          {t('msg_codes_voucher_type_id')}
        </span>
        <span
          onClick={goToVoucherTypePage}
          onKeyPress={goToVoucherTypePage}
          role="button"
          tabIndex={0}
          className="voucher-type-id"
          data-testid="voucher-type-id"
        >
          {typeId}
        </span>
        { isEditable && (
        <span
          className="voucher-type-id-edit-button"
          data-testid="voucher-type-id-edit-button"
          onClick={openAddVoucherTypeId}
          onKeyPress={openAddVoucherTypeId}
          role="button"
          tabIndex={0}
        >
          <Icon className="pencil-icon" id="pencil-icon">ico-edit</Icon>
        </span>
        )}
      </span>
    );
  }, [isEditable]);

  const getVoucherBatchStatus = () => {
    const voucherStatusMessages = {
      Active: 'msg_codes_agreementStatus_active',
      Inactive: 'msg_codes_agreementStatus_inactive',
    };

    return !isNew && (
      <span
        className="voucher-batch-status"
      >
        {t('msg_codes_agreements_agreementStatus')}: {t(voucherStatusMessages[voucherBatch.voucherStatus])}
      </span>
    );
  };

  const voucherStatuses = statuses.slice();
  if (!isNew) {
    voucherStatuses.push({ value: 4, label: t('msg_codes_codes_status_deactivated') });
  }

  const useCountLimitCheck = amount => isEmpty(amount) || (Number(amount) > 0
    && Number(amount) <= 1000000);
  const quantityLimitCheck = amount => isEmpty(amount) || (Number(amount) > 0
    && Number(amount) <= 900000);


  return (
    <div
      className="voucher-batch-info-section-wrapper"
      data-testid="voucher-batch-info-section-wrapper"
    >
      <Collapsible
        className="voucher-batch-section-container"
        id="voucher-batch-info-section-container"
        defaultIsOpen
        heading={getHeaderText()}
        rightContent={isNew ? '' : getHeaderDescriptionText()}
      >
        <>
          <Flex
            colSpan={6}
            className="voucher-batch-details-top"
            id="voucher-batch-details-top"
          >
            {getVoucherTypeId()}{getVoucherBatchStatus()}
            {readOnlyBatch && (
              <div data-testid="note" className="note">
                {t('msg_codes_read_only_fields')}
              </div>
            )}
          </Flex>
          <div
            className="voucher-batch-details"
            data-tesid="voucher-batch-details"
          >
            <Layout
              className="voucher-batch-details-layout"
              id="voucher-batch-details-layout"
            >
              <Flex
                colSpan={5}
                className="voucher-batch-details-left"
                id="voucher-batch-details-left"
              >
                {renderSelect('msg_codes_voucher_purpose', true, voucherBatch.voucherPurposeId, 'voucherPurposeId', purposes, !isEditable)}
                {renderInput('msg_codes_codes_quantity', true, voucherBatch.quantity, 'quantity', !(isNew && isEditable), true, 'Number of voucher codes in this batch. Quantity must be 1 through 900000', quantityLimitCheck)}
                {renderInput('msg_codes_use_count', true, isMoneyVoucherType ? 1 : voucherBatch.useCount, 'useCount', !(isEditable && isNew) || isMoneyVoucherType, true, 'Number of times the code can be redeemed. Use count must be 1 through 1000000', useCountLimitCheck)}
                {renderSelect('msg_codes_distributor', true, voucherBatch.distributorId, 'distributorId', distributors, !(isNew && isEditable), true, 'Company printing and distributing the vouchers')}
              </Flex>
              <Flex
                colSpan={5}
                className="voucher-batch-details-right"
                id="voucher-batch-details-right"
              >
                {renderSelect('msg_codes_agreements_agreementStatus', true, voucherBatch.voucherStatusId, 'voucherStatusId', voucherStatuses, !(isNew && isEditable), false, '', !isMoneyVoucherType && !hasSkus ? [0] : [])}
                {(!hasSkus && isProductVoucherType) && (<span className="voucher-batch-field-note" id="voucher-batch-status-note">Note: Status can not be Active, while there is no SKU associated.</span>)}
                {renderInput('msg_codes_orderListAdmin_batchControlLabel_dropdown', true, voucherBatch.controlLabel, 'controlLabel', !(isNew && isEditable), false)}
                {renderTextAreaWithMaxCharLimit('msg_codes_batch_description', true, voucherBatch.batchDescription, 'batchDescription', !isEditable, 1024, false)}
              </Flex>
            </Layout>
          </div>
          <div
            className="voucher-batch-details-form"
            id="voucher-batch-info-details-form"
          >
            {!isNew && (
              <DownloadSection
                activeCount={voucherBatch.activeQuantity}
                inactiveCount={voucherBatch.inactiveQuantity}
                deactivatedCount={voucherBatch.cancelledQuantity}
                handleBatchActions={handleBatchActions}
                statusId={voucherBatch.voucherStatusId}
                state={voucherBatch.state}
                isMoneyVoucherType={isMoneyVoucherType}
                hasSkus={hasSkus}
                canEditVoucherBatch={isEditable}
                batchId={voucherBatch.voucherBatchId}
              />
            )}
          </div>
        </>
      </Collapsible>
    </div>
  );
};

VoucherBatchInfoSection.propTypes = {
  voucherBatch: PropTypes.object,
  renderInput: PropTypes.func,
  renderSelect: PropTypes.func,
  renderTextAreaWithMaxCharLimit: PropTypes.func,
  hasSkus: PropTypes.bool,
  isNew: PropTypes.bool,
  isProductVoucherType: PropTypes.bool,
  isMoneyVoucherType: PropTypes.bool,
  handleBatchActions: PropTypes.func,
  openAddVoucherTypeId: PropTypes.func,
  createdBy: PropTypes.string,
  creationDate: PropTypes.string,
  canEditVoucherBatch: PropTypes.bool,
  readOnlyBatch: PropTypes.bool,
  npRegion: PropTypes.string,
};

VoucherBatchInfoSection.defaultProps = {
  renderInput: () => null,
  renderSelect: () => null,
  renderTextAreaWithMaxCharLimit: () => null,
  voucherBatch: {},
  hasSkus: false,
  isNew: true,
  isProductVoucherType: false,
  isMoneyVoucherType: true,
  handleBatchActions: () => null,
  openAddVoucherTypeId: () => null,
  createdBy: '',
  creationDate: '',
  canEditVoucherBatch: false,
  readOnlyBatch: false,
  npRegion: '',
};

export default VoucherBatchInfoSection;
