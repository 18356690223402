/**
 * TODO: Remove old CommentTooltip component once CommentTooltipNewUX
 * has been used for all order types. And rename this componenet to be CommentTooltip.
*/
import React from 'react';
import {
  Icon,
  Tooltip,
} from '@partner-global-ui/components';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import './Tooltip.scss';
import PropTypes from 'prop-types';
import accountTypeMap from '../../constants/accountTypeMap.constants';
import orderStatuses from '../../constants/order.constants';

const CommentTooltip = ({
  order, commentRef,
}) => {
  const { t } = useTranslation();
  const { accountType } = useSelector(state => state.user);
  const {
    orderStatus,
    creationDate,
    amendmentComments,
    rejectedComments,
  } = order;

  const messageIconName = 'message';
  const renderCommentOverlay = () => {
    const comment = {
      header: t('msg_codes_orderListAdmin_comment_label'),
      dateAndTime: moment(creationDate).format('hh:mmA MM/DD/YYYY'),
      message: orderStatus === orderStatuses.REJECTED ? rejectedComments : amendmentComments,
    };

    const userAccounttype = t(accountTypeMap[accountType]);

    return (
      <div className="comment-overlay" data-testid="comment-overlay">
        <span className="comment-overlay-header" data-testid="comment-overlay-header">
          {comment.header}
        </span>
        <hr data-testid="comment-overlay-divider" />
        <div className="comment-overlay-content" data-testid="comment-overlay-content">
          <div className="comment-overlay-content-info" data-testid="comment-overlay-content-info">
            <span className="comment-account-type" data-testid="comment-account-type">{userAccounttype}</span>
            <span className="comment-date-time" data-testid="comment-date-time">{comment.dateAndTime}</span>
          </div>
          <div className="comment-overlay-content-message" data-testid="comment-overlay-content-message">{t(comment.message)}</div>
        </div>
      </div>
    );
  };
  return (
    <div className="comment" data-testid="comment">
      <Tooltip
        id="order-comment-tooltip"
        content={renderCommentOverlay(order)}
        anchor={commentRef}
      >
        <span data-testid="icon">
          <Icon id="comment-icon" size={14}>{messageIconName}</Icon>
        </span>
      </Tooltip>
    </div>
  );
};

CommentTooltip.propTypes = {
  order: PropTypes.object,
  commentRef: PropTypes.object,
};

CommentTooltip.defaultProps = {
  order: {},
  commentRef: {},
};

export default CommentTooltip;
