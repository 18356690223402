import React from 'react';
import PropTypes from 'prop-types';
import {
  DataTable,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableCell,
} from '@partner-global-ui/components';
import moment from 'moment-timezone';


function SkuInfoSubRows({
  skuAvailabilities,
}) {
  const formatDate = (date) => {
    const momentObject = moment.utc(date, ['DD-MMM-YY hh.mm.ss.SSSSSS A', moment.ISO_8601]);
    if (!momentObject.isValid()) return '-';
    return momentObject.tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm z [(UTC ]Z[)]');
  };

  return (
    <DataTable
      key="sku-info-sub-table"
      subtable
      className="sku-info-sub-table"
      columnTemplate="repeat(6, 1fr)"
      hasVerticalScroll
    >
      <TableHeader>
        <TableHeaderCell />
        <TableHeaderCell />
        <TableHeaderCell />
        <TableHeaderCell />
        <TableHeaderCell />
        <TableHeaderCell />
      </TableHeader>
      {
        skuAvailabilities.map(({
          startDate,
          endDate,
          country: {
            name: countryName,
          },
          skuId,
        }) => {
          return (
            <TableRow
              name={`SkuInfoSubRow-${skuId}`}
              id={`thirdPartyProduct-${skuId}`}
              key={skuId}
              data-testid={`SkuInfoSubRow-${skuId}`}
              expandable={false}
            >
              <TableCell id="first-column" />
              <TableCell id="country">
                <div data-testid="country">{countryName}</div>
              </TableCell>
              <TableCell id="start-date">
                <div data-testid="start-date">{formatDate(startDate)}</div>
              </TableCell>
              <TableCell id="end-date">
                <div data-testid="end-date">{formatDate(endDate)}</div>
              </TableCell>
              <TableCell id="price">
                <div data-testid="price">-</div>
              </TableCell>
              <TableCell id="price">
                <div data-testid="price">-</div>
              </TableCell>
            </TableRow>
          );
        })
      }
    </DataTable>
  );
}

SkuInfoSubRows.propTypes = {
  skuAvailabilities: PropTypes.array,
};

SkuInfoSubRows.defaultProps = {
  skuAvailabilities: PropTypes.array,
};

export default SkuInfoSubRows;
