import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  Container,
  Collapsible,
  Divider,
  Flex,
  Icon,
  Layout,
  TextInput,
  RadioGroup,
  Select,
  Tooltip,
  TextArea,
} from '@partner-global-ui/components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import StaticText from '../../common/StaticText';
import roleKeys from '../../../utils/accessControl/roleKeys';
import { usePermission } from '../../../utils/accessControl/hasPermission';
import additionalRedemptionCheck, {
  handleTotalManufactureAndFreightCostDisplay,
} from './methods/orderInfoMethods';
import * as callbackGateways from '../../../constants/callbackGateways.constants';
import integratorPartnerTypes from '../../../constants/integratorPartnerTypes.constants';
import { registrationRegionOptions, optionsTranslationCreator } from '../../../constants/filterOptions.constants';
import { currenciesRegions } from '../../../constants/currencies.constants';
import { ACTIVE } from '../../../constants/partnerState.constants';
import { VouchersPartnerRequester } from '../../../constants/userRoles.constants';
import { getActiveAgreementRegions } from '../../../actions/ordersActions';
import {
  setPartnerForOrder,
  getActiveAgreementVersionForOrder,
} from '../../../actions/orderActions';
import { loadPartner } from '../../../actions/partnerActions';

const sftpRedemptionOptions = {
  YES: 'YES',
  NO: 'NO',
  BOTH: 'BOTH',
};

const OrderInfo = ({
  orderForm,
  order,
  user,
  partners,
  onChange,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { activeAgreementVersion } = orderForm;
  const {
    additionalRedemptionCheckRequired,
    cardManufacturePriceCurrency,
    freightCost = '0.00',
    freightCostTaxPercentage = '0.00',
    newOrder,
    orderComments,
    orderNumber,
    orderStatus,
    orderLines,
    orderHistory,
    orderReference,
    partnerName,
    partnerId,
    partnerSftpId,
    poNumber,
    region,
    totalTax,
  } = order;
  const {
    roleCode,
    domainPartner,
  } = user;

  // State Variables
  const [redemptionCheckRequired, setRedemptionCheckRequired] = useState(
    additionalRedemptionCheckRequired,
  );
  const [displayedTotalTax, setDisplayedTotalTax] = useState(totalTax);
  const [selectCurrency, setSelectCurrency] = useState('');
  const [voucherCodes, setVoucherCodes] = useState('');
  const [filteredRegions, setFilteredRegions] = useState([]);
  const [partnerSftpList, setPartnerSftpList] = useState([]);
  const [defaultPartnerSftpList, setDefaultPartnerSftpList] = useState([]);
  const [totalCost, setTotalCost] = useState(0);
  const [voucherRedemptionCallbackFormGroup, setVoucherRedemptionCallbackFormGroup] = useState({});
  const [freightCostTaxPercentageErrorMessage, setFreightCostTaxPercentageErrorMessage] = useState('');

  const basePermissions = {
    editReference: usePermission(roleKeys.order.editReference),
    editContractingPartner: usePermission(roleKeys.order.editContractingPartner),
    editContractingSIERegion: usePermission(roleKeys.order.editContractingSIERegion),
    editAdditionalRedemptionCheck: usePermission(roleKeys.order.editAdditionalRedemptionCheck),
    editComments: usePermission(roleKeys.order.editComments),
    editManufacturingCostCurrency: usePermission(roleKeys.order.editManufacturingCostCurrency),
    editFreightCost: usePermission(roleKeys.order.editFreightCost),
    editFreightCostTax: usePermission(roleKeys.order.editFreightCostTax),
    editSftpId: usePermission(roleKeys.order.editSftpId, orderStatus),
    editPurchaseOrderNumber: usePermission(roleKeys.order.editPurchaseOrderNumber),
  };

  const redemptionCheckOptions = [
    {
      label: t('msg_codes_common_yes'),
      value: 'true',
    },
    {
      label: t('msg_codes_cta_no'),
      value: 'false',
    },
  ];

  // Partner Intergrator Filtering
  const partnerOptions = partners.filter(
    partnerOption => partnerOption.partnerType === integratorPartnerTypes.INTEGRATOR,
  ).reduce((acc, curr) => [
    ...acc,
    {
      label: curr.name,
      value: curr.partnerCode,
      id: curr.partnerId,
    },
  ], []);

  const isSelectedPartner = partnerOptions.find(
    p => p.label === partnerName,
  );

  const handleOrderReference = (e) => {
    if (typeof e.persist === 'function') {
      e.persist();
    }

    onChange({
      target: {
        name: 'orderReference',
        value: e.target.value,
      },
    });
  };

  const handleOrderComments = (e) => {
    console.log(e);
    if (typeof e.persist === 'function') {
      e.persist();
    }

    onChange({
      target: {
        name: 'orderComments',
        value: e.target.value,
      },
    });
  };

  const setRegionOptions = id => dispatch(getActiveAgreementRegions(id))
    .then(
      res => setFilteredRegions(res.reduce((acc, curr) => [
        ...acc,
        {
          label: curr,
          value: curr,
          attr: {
            'data-testid': curr,
          },
        },
      ], [])),
    );

  const handlePartnerChange = (e) => {
    if (e.target.value) {
      const settingPartner = partnerOptions.find(
        p => p.label === Object.values(e.target.value)[0],
      );
      // retrieve new partner and associated partnerSftps
      dispatch(loadPartner(settingPartner.id, ACTIVE)).then((res) => {
        if (res) {
          if (typeof res.partnerSftpMapList === 'undefined') {
            onChange({ target: { name: 'partnerSftpId', value: null } });
          } else if (res.partnerSftpMapList.length === 1) {
            onChange({
              target: {
                name: 'partnerSftpId',
                value: res.partnerSftpMapList[0].sftpId,
              },
            });
            setVoucherCodes({
              label: res.partnerSftpMapList[0].sftpProperties.description,
              value: res.partnerSftpMapList[0].sftpId,
            });
          }
        }
      });
      onChange({ target: { name: e.target.name, value: e.target.value } });
      onChange({ target: { name: 'partner', value: Object.values(e.target.value)[0] } });
      onChange({ target: { name: 'partnerName', value: Object.values(e.target.value)[0] } });
      onChange({ target: { name: 'partnerId', value: settingPartner.id } });
      setRegionOptions(settingPartner.id);
    } else {
      onChange({ target: { name: 'partnerName', value: '' } });
      onChange({ target: { name: 'partner', value: '' } });
      onChange({ target: { name: 'partnerId', value: '' } });
    }
    setVoucherCodes('');
  };

  const partnerActiveRegions = optionsTranslationCreator(registrationRegionOptions, t);
  const getSelectedRegion = useCallback(() => {
    const selectedRegion = partnerActiveRegions.find(
      val => val.value === region,
    );
    return selectedRegion;
  }, [region, partnerActiveRegions]);

  const createSftpOptions = (mapList) => {
    if (typeof mapList === 'undefined') {
      return [{
        label: t('msg_codes_orderDetails_noDeliverySftp'),
        value: null,
        redemptionCheck: sftpRedemptionOptions.BOTH,
      }];
    }

    const optionsList = mapList.map(sftpMapList => ({
      ...sftpMapList,
      label: sftpMapList.sftpProperties.description,
    })).sort((a, b) => {
      // Sort options alphabetically
      if (a.label > b.label) {
        return 1;
      }
      if (a.label < b.label) {
        return -1;
      }
      return 0;
    }).reduce((acc, curr) => [
      ...acc,
      {
        label: curr.label,
        value: curr.sftpId,
        redemptionCheck: curr.sftpRedemptionCheckOption,
      },
    ], [{
      label: t('msg_codes_orderDetails_noDeliverySftp'),
      value: '',
      redemptionCheck: sftpRedemptionOptions.BOTH,
    }]);

    return optionsList;
  };

  let currenciesOptions = typeof region === 'undefined'
    ? currenciesRegions['']
    : [
      ...currenciesRegions[region],
    ];

  const createDefaultSftpOptions = (id) => {
    dispatch(loadPartner(id, ACTIVE)).then((response) => {
      if (response) {
        const sftpOptions = createSftpOptions(response?.partnerSftpMapList);
        setDefaultPartnerSftpList(sftpOptions);
        setPartnerSftpList(sftpOptions);
      } else {
        setPartnerSftpList([]);
      }
    });
  };

  const autoSelectSftp = (currentSftps, sftpType) => {
    const selectedOption = currentSftps.find((option) => {
      if (option.redemptionCheck === sftpRedemptionOptions.BOTH) {
        return false;
      }

      if (sftpType) {
        return option.redemptionCheck !== sftpRedemptionOptions.NO;
      }
      return option.redemptionCheck !== sftpRedemptionOptions.YES;
    });
    if (selectedOption) {
      onChange({
        target: {
          name: 'partnerSftpId',
          value: selectedOption.value,
        },
      });
      setVoucherCodes(selectedOption);
    }
  };

  useEffect(() => {
    if (typeof partnerId === 'number') {
      if (region) {
        setRegionOptions(partnerId);
      }
      createDefaultSftpOptions(partnerId);
    }

    if (cardManufacturePriceCurrency) {
      const existingCardManufacturePriceCurrency = cardManufacturePriceCurrency
        ? currenciesOptions.find(option => option.label === cardManufacturePriceCurrency)
        : '';
      setSelectCurrency(existingCardManufacturePriceCurrency);
    }
  }, []);

  useEffect(() => {
    currenciesOptions = typeof region === 'undefined'
      ? currenciesRegions['']
      : [
        ...currenciesRegions[region],
      ];
  }, [region]);

  useEffect(() => {
    if (
      newOrder
      && voucherCodes === ''
    ) {
      if (
        roleCode === VouchersPartnerRequester.roleCode
        && partnerSftpList.length === 2
      ) {
        setVoucherCodes(partnerSftpList[1]);
        onChange({
          target: {
            name: 'partnerSftpId',
            value: partnerSftpList[1].value,
          },
        });
      } else if (additionalRedemptionCheckRequired !== null) {
        autoSelectSftp(partnerSftpList, additionalRedemptionCheckRequired);
      }
    }
    if (!newOrder && partnerSftpId) {
      const selectedSftp = partnerSftpList.find(sftp => sftp.value === partnerSftpId);
      setVoucherCodes(selectedSftp);
    }
  }, [partnerSftpList, additionalRedemptionCheckRequired]);

  useEffect(() => {
    if (
      typeof partnerId !== 'number'
      && roleCode === VouchersPartnerRequester.roleCode
      && partners.length === 1
    ) {
      dispatch(setPartnerForOrder(partners[0]));
      setRegionOptions(domainPartner.partnerId);
      createDefaultSftpOptions(domainPartner.partnerId);
    }
  }, [partnerId, roleCode, domainPartner, partners]);


  useEffect(() => {
    createDefaultSftpOptions(partnerId);
  }, [partnerId]);

  const resetCurrencyField = useCallback(() => {
    setSelectCurrency('');
    onChange({
      target: {
        name: 'cardManufacturePriceCurrency',
        value: null,
      },
    });
  });

  const handleSelectRegion = (e) => {
    if (typeof e.persist === 'function') {
      e.persist();
    }

    onChange({
      persist: () => { },
      target: {
        name: 'region',
        value: e.target.value.value,
      },
    });

    resetCurrencyField();

    const id = typeof partnerId === 'undefined'
      ? partners.find(p => p.name === order.partnerName).partnerId
      : partnerId;
    dispatch(getActiveAgreementVersionForOrder(id, e.target.value.value));
  };

  const redemptionCheckOnChange = useCallback((e) => {
    setRedemptionCheckRequired(e.target.value);
    onChange({
      persist: () => { },
      target: {
        name: 'additionalRedemptionCheckRequired',
        value: e.target.value,
      },
    });
    const required = e.target.value === 'true';
    autoSelectSftp(defaultPartnerSftpList, required);
  }, [defaultPartnerSftpList]);

  // Order Status'
  const showPartnerSelect = roleCode !== VouchersPartnerRequester.roleCode;
  const isNew = typeof orderStatus === 'undefined';
  const isDraft = !isNew && orderStatus === 'DRAFT_ORDER';
  const isAmendmentRequested = !isNew && orderStatus === 'PENDING_DRAFT_AMENDMENT';
  const isPendingApproval = !isNew && orderStatus === 'SUBMITTED_PENDING_APPROVAL';
  const isApproved = !isNew && orderStatus === 'APPROVED_ORDER';
  const hasLines = orderLines.length > 0;

  // Redemption check
  useEffect(() => {
    const callbackFormGroup = additionalRedemptionCheck(
      callbackGateways[activeAgreementVersion.voucherRedemptionCallbackGateway],
      activeAgreementVersion.voucherRedemptionCallbackMandatory,
    );
    setVoucherRedemptionCallbackFormGroup(callbackFormGroup);
    setRedemptionCheckRequired(callbackFormGroup.voucherRedemptionCallback);
  }, [activeAgreementVersion]);

  const displayRedemptionCheckRadios = additionalRedemptionCheckRequired !== null || (
    !voucherRedemptionCallbackFormGroup.hidden
    && filteredRegions.length > 0
    && typeof partnerName !== 'undefined'
    && partnerName !== ''
    && typeof region !== 'undefined'
    && region !== ''
    && region !== 'select'
  );
  const isRejected = orderStatus && orderStatus === 'REJECTED';
  const isCancelled = order.orderStatus && orderStatus === 'CANCELLED_ORDER';
  const redemptionCheckValue = {
    [true]: () => redemptionCheckRequired,
    [isNew && typeof additionalRedemptionCheckRequired === 'undefined']: () => {
      return voucherRedemptionCallbackFormGroup.voucherRedemptionCallback;
    },
    [additionalRedemptionCheckRequired !== null && redemptionCheckRequired === null]: () => {
      return additionalRedemptionCheckRequired.toString();
    },
  }.true();

  // Permissions
  const permissions = {
    editReference: basePermissions.editReference
      && (isNew || isDraft || isPendingApproval || isAmendmentRequested || isApproved),
    editContractingPartner: basePermissions.editContractingPartner
      && (isNew || isDraft) && !hasLines,
    editContractingSIERegion: basePermissions.editContractingSIERegion
      && (isNew || isDraft || isAmendmentRequested) && !hasLines
      && ((typeof partnerName !== 'undefined' && partnerName !== '') || !showPartnerSelect) && filteredRegions.length > 0,
    editAdditionalRedemptionCheck: basePermissions.editAdditionalRedemptionCheck
      && (isNew || isDraft || isAmendmentRequested) && !hasLines,
    editComments: basePermissions.editComments
      && (isNew || isDraft || isPendingApproval || isAmendmentRequested || isApproved),
    editManufacturingCostCurrency: !partnerName
      || !region
      || (basePermissions.editManufacturingCostCurrency
        && (isNew || isDraft || isAmendmentRequested)
        && !hasLines
      ),
    editFreightCost: basePermissions.editFreightCost
      && (isNew || isDraft || isAmendmentRequested) && !hasLines,
    editFreightCostTax: basePermissions.editFreightCostTax
      && (isNew || isDraft || isAmendmentRequested) && !hasLines,
    editSftpId: basePermissions.editSftpId,
    editPurchaseOrderNumber: basePermissions.editPurchaseOrderNumber,
  };

  // find most recent submission and retrieve user name
  const mostRecentSubmission = orderHistory ? orderHistory.find(historyLine => historyLine.status.includes('SUBMITTED')) : '';
  const submittedBy = mostRecentSubmission ? mostRecentSubmission.byUser : '';

  const handleSelectCurrency = (e) => {
    if (typeof e.persist === 'function') {
      e.persist();
    }
    if (e.target.value !== '') {
      setSelectCurrency(e.target.value);
      onChange({
        target: {
          name: 'cardManufacturePriceCurrency',
          value: e.target.value.label,
        },
      });
    }
  };


  const handleTotalFreightCost = (cost, taxPercent) => {
    let sum = 0;
    sum = cost * (taxPercent / 100);
    return sum;
  };


  const handleSftpOption = (e) => {
    if (typeof e.persist === 'function') {
      e.persist();
    }
    onChange({
      target: {
        name: 'partnerSftpId',
        value: e.target.value.value,
      },
    });
    setVoucherCodes(e.target.value);
  };

  const taxTooltip = (<div className="total-tax-content">{t('msg_codes_orderDetailsAdmin_totalTax_tooltip')}</div>);

  useEffect(() => {
    if ((newOrder || isNew || isDraft || isAmendmentRequested) && hasLines) {
      const freightCostTax = freightCost * (freightCostTaxPercentage / 100);
      const newTotalTax = Number(orderLines.reduce((current, next) => {
        const manufactureTotal = Number(next.cardManufacturePrice);
        const manufactureTax = next.tax || 0;
        const taxTotal = manufactureTotal * manufactureTax * 0.01;
        return current + (taxTotal * next.quantity);
      }, 0)) + freightCostTax;
      setDisplayedTotalTax(newTotalTax.toFixed(4));
    }
  }, [totalTax, newOrder, orderStatus, orderLines]);

  useEffect(() => {
    let totalFreightTax = 0;
    totalFreightTax = handleTotalFreightCost(freightCost, freightCostTaxPercentage);
    setTotalCost(totalFreightTax);
    if (Number(freightCostTaxPercentage).toFixed(2) > 100) {
      setFreightCostTaxPercentageErrorMessage('Freight Tax Cost % should not be above 100');
    } else if (Number(freightCostTaxPercentage).toFixed(2) < 0) {
      setFreightCostTaxPercentageErrorMessage('Freight Tax Cost % should not be below 0');
    } else {
      setFreightCostTaxPercentageErrorMessage('');
    }
  }, [freightCost, freightCostTaxPercentage]);

  return (
    <Layout id="agency-order-info">
      <Flex colSpan="12">
        <Collapsible
          id="agency-order-details"
          heading={`${t('msg_codes_NewOrder_orderInformation')} ${typeof partnerName === 'undefined' ? '' : partnerName}`}
          rightContent={
            !newOrder && (t('msg_codes_newOrder_createdBy', {
              name: submittedBy,
              date: moment(order.creationDate).format('MM/DD/YY'),
              interpolation: {
                escapeValue: false,
              },
            }))
          }
          defaultIsOpen
        >
          <Container className="info-container">
            <Layout className="flex-wrapper">
              <Flex colSpan={5}>
                <TextInput
                  testId="order-info"
                  id="order-name"
                  label={t('msg_codes_newOrder_orderName')}
                  width={375}
                  value={orderReference}
                  onChange={handleOrderReference}
                  required
                  disabled={!permissions.editReference}
                  placeholder={`${t('msg_codes_codeProdCat_typeSomething_dropdown')}...`}
                />
                {(showPartnerSelect
                  && (
                    <div className="select-wrapper">
                      <Select
                        label={t('msg_codes_agreements_contractingPartner')}
                        placeholder={t('msg_codes_select')}
                        options={partnerOptions}
                        onChange={handlePartnerChange}
                        name="partnerName"
                        value={isSelectedPartner}
                        disabled={!permissions.editContractingPartner}
                        required
                        msg_codes_select
                        id="partner"
                      />
                    </div>
                  )
                )}
                <div className="select-wrapper">
                  <Select
                    label={t('msg_codes_agreements_contractingSIEregion')}
                    placeholder={t('msg_codes_common_select')}
                    options={filteredRegions}
                    value={getSelectedRegion()}
                    onChange={handleSelectRegion}
                    disabled={!permissions.editContractingPartner}
                    name="region"
                    id="region"
                    required
                  />
                </div>
                {displayRedemptionCheckRadios && (
                  <div className="checkbox-label">
                    <div className="pucl-checkbox">
                      <RadioGroup
                        id="additionalRedemptionCheckRequired"
                        label={t('msg_codes_orderDetail_AdditionalRedemptionCheckRequired')}
                        options={redemptionCheckOptions}
                        value={redemptionCheckValue !== null && redemptionCheckValue.toString()}
                        onChange={redemptionCheckOnChange}
                        disabled={voucherRedemptionCallbackFormGroup.disabled
                          || !permissions.editAdditionalRedemptionCheck}
                        required
                      />
                    </div>
                  </div>
                )}
                {partnerSftpList.length >= 1 && (
                  <div className="select-wrapper">
                    <Select
                      label={t('msg_codes_NewOrder_DeliverVoucherCodesTo')}
                      placeholder={t('msg_codes_orderDetails_noDeliverySftp')}
                      options={partnerSftpList}
                      value={voucherCodes}
                      onChange={handleSftpOption}
                      name="partnerSftpId"
                      required={false}
                      id="sftp-location"
                      disabled={!partnerName || (typeof orderNumber !== 'undefined'
                        && !permissions.editSftpId
                      )}
                    />
                  </div>
                )}
                <TextInput
                  label={t('msg_codes_NewOrder_PurchaseOrder')}
                  testId="po-name"
                  width={375}
                  value={poNumber}
                  required={false}
                  onChange={e => onChange({
                    target: {
                      name: 'poNumber',
                      value: e.target.value,
                    },
                  })}
                  id="po-number"
                  disabled={
                    isRejected
                    || isCancelled
                    || (
                      typeof orderNumber !== 'undefined' // Order has not been created yet
                      && !permissions.editPurchaseOrderNumber
                    )}
                  placeholder={`${t('msg_codes_codeProdCat_typeSomething_dropdown')}...`}
                />
              </Flex>
              <Flex colSpan={1} offset={1}>
                <Divider secondary vertical />
              </Flex>
              <Flex colSpan={5}>
                <TextInput
                  label={t('msg_codes_NewOrder_freightCostTaxExcluding')}
                  testId="freight-cost"
                  id="freight-cost"
                  name="freightCost"
                  onBlur={e => onChange({
                    target: {
                      name: 'freightCost',
                      value: e.target.value,
                    },
                  })}
                  width={375}
                  value={Number(freightCost).toFixed(4)}
                  disabled={!permissions.editFreightCost}
                  required={false}
                  type="number"
                />
                <TextInput
                  label={t('msg_codes_NewOrder_freightCostTaxPercentage')}
                  testId="freight-tax"
                  id="freight-tax"
                  name="freightCostTaxPercentage"
                  onBlur={e => onChange({
                    target: {
                      name: 'freightCostTaxPercentage',
                      value: e.target.value,
                    },
                  })}
                  width={375}
                  value={Number(freightCostTaxPercentage).toFixed(2)}
                  disabled={!permissions.editFreightCostTax}
                  required={false}
                  type="number"
                  // errorMessage={freightCostTaxPercentageErrorMessage}
                  hasError={freightCostTaxPercentageErrorMessage !== ''}
                />
                <div className="static-text">
                  <StaticText
                    label={t('msg_codes_NewOrder_freightCostTax')}
                    value={parseFloat(totalCost).toFixed(2)}
                    testId="freight-cost-tax"
                  />
                </div>
                <div className="manufacturing">
                  <StaticText
                    label={t('msg_codes_NewOrder_manufactureFreightCost')}
                    value={handleTotalManufactureAndFreightCostDisplay(order, totalCost).toFixed(2)}
                    required={region}
                    testId="manufacturing-frieght-cost"
                  />
                  {region && (
                    <Select
                      name="currency"
                      placeholder={t('msg_codes_common_select')}
                      options={currenciesOptions}
                      onChange={handleSelectCurrency}
                      disabled={!permissions.editManufacturingCostCurrency}
                      value={selectCurrency}
                      id="currency"
                    />
                  )}
                </div>
                <div className="total-tax">
                  <StaticText
                    label={t('msg_codes_NewOrder_TotalTax')}
                    value={displayedTotalTax}
                    testId="total-tax"
                  />
                  <Tooltip
                    className="info-tooltip"
                    content={taxTooltip}
                    position="top"
                  >
                    <Icon size={16}>info</Icon>
                  </Tooltip>
                </div>
                <TextArea
                  id="order-comments"
                  label={t('msg_codes_newOrder_orderComments')}
                  placeholder={`${t('msg_codes_codeProdCat_typeSomething_dropdown')}...`}
                  required={false}
                  value={orderComments}
                  disabled={!permissions.editComments}
                  onChange={handleOrderComments}
                />
                <div className="warning">
                  <Icon size="small">
                    ico-warning
                  </Icon>
                  {t('msg_codes_NewOrder_noteOrder')}
                </div>
              </Flex>
            </Layout>
          </Container>
        </Collapsible>
      </Flex>
    </Layout>
  );
};

OrderInfo.propTypes = {
  orderForm: PropTypes.object,
  order: PropTypes.object,
  user: PropTypes.object,
  partners: PropTypes.array,
  onChange: PropTypes.func,
};

OrderInfo.defaultProps = {
  orderForm: {},
  order: {},
  user: {},
  partners: [],
  onChange: () => { },
};

export default OrderInfo;
