import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

export class RouteChange extends Component {
  componentDidMount() {
    this.routeChanged();
  }

  componentDidUpdate(prevProps) {
    const { location: { pathname } } = this.props;

    if (prevProps.location.pathname === pathname) return;
    this.routeChanged();
  }

  routeChanged() {
    const {
      location, actions, store, user,
    } = this.props;

    if (typeof actions !== 'undefined') {
      actions.forEach((action) => {
        store.dispatch(action(location, user, store.getState().app));
      });
    }
  }

  render() {
    return null;
  }
}

RouteChange.propTypes = {
  location: PropTypes.object,
  store: PropTypes.object,
  user: PropTypes.object,
};

RouteChange.defaultProps = {
  location: {},
  store: {},
  user: {},
};

export default withRouter(RouteChange);
