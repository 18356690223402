import * as CallbackGateways from '../../../../constants/callbackGateways.constants';

export function handleTotalManufactureAndFreightCostDisplay(
  {
    freightCost,
    totalManufactureAndFreightCost: loadedTotalManufactureAndFreightCost,
    orderLines,
  },
  totalCost,
) {
  if (orderLines.length === 0) {
    return Number(parseFloat(freightCost));
  }

  let totalMAFCDisplay = Number(loadedTotalManufactureAndFreightCost);
  let totalManufactureAndFreightCost = Number(orderLines.reduce((acc, curr) => {
    const {
      cardManufacturePrice,
      quantity,
    } = curr;

    return acc + Number(cardManufacturePrice * quantity);
  }, 0));

  totalManufactureAndFreightCost = parseFloat(totalManufactureAndFreightCost);
  totalManufactureAndFreightCost = Number(totalManufactureAndFreightCost)
    + Number(parseFloat(freightCost));

  totalMAFCDisplay = !Number.isNaN(totalManufactureAndFreightCost)
    ? (Number(totalManufactureAndFreightCost) + totalCost)
    : Number(loadedTotalManufactureAndFreightCost);

  // Excluding Tax
  totalMAFCDisplay = Number(loadedTotalManufactureAndFreightCost);

  return totalMAFCDisplay;
}

export function isAdditionalRedemptionCheckRequired(
  voucherRedemptionCallback, voucherRedemptionCallbackMandatory,
) {
  if (voucherRedemptionCallback === CallbackGateways.NO_GATEWAY) {
    return false;
  }
  if (voucherRedemptionCallbackMandatory) {
    return true;
  }
  return null;
}

function isAdditionalRedemptionCheckRequiredDisabled(
  voucherRedemptionCallback, voucherRedemptionCallbackMandatory,
) {
  return voucherRedemptionCallback === CallbackGateways.NO_GATEWAY
    || voucherRedemptionCallbackMandatory;
}

export default (voucherRedemptionCallback, voucherRedemptionCallbackMandatory) => {
  if (
    typeof voucherRedemptionCallback !== 'string'
    || typeof voucherRedemptionCallbackMandatory !== 'boolean'
  ) {
    return {
      voucherRedemptionCallback: null, disabled: true, hidden: true,
    };
  }
  return {
    voucherRedemptionCallback: isAdditionalRedemptionCheckRequired(
      voucherRedemptionCallback, voucherRedemptionCallbackMandatory,
    ),
    disabled: isAdditionalRedemptionCheckRequiredDisabled(
      voucherRedemptionCallback, voucherRedemptionCallbackMandatory,
    ),
    hidden: false,
  };
};
