import _ from 'lodash';

const newOrderProps = [
  'region',
  'orderReference',
  'poNumber',
  'orderComments',
  'additionalRedemptionCheckRequired',
  'partnerSftpId',
  'partnerId',
  'freightCost',
  'freightCostTaxPercentage',
  'cardManufacturePriceCurrency',
];

const updateOrderProps = [
  ...newOrderProps,
  'orderNumber',
  'orderLines',
  'applyFeeWaiver',
  'feeWaiverReason',
  'codeFee',
  'codeFeeCurrency',
];

const orderLine = [
  'itemReference',
  'quantity',
  'voucherCatalogId',
  'orderLineId',
  'tax',
  'cardManufacturePrice',
  'branded',
  'customId',
];

const grcOrderLine = [
  'orderLineId',
  'quantity',
  'voucherCatalogId',
  'scheduledActivationDate',
  'scheduledBatchExpirationDate',
];

export function pickNewOrder(newOrder) {
  return _.pick(newOrder, newOrderProps);
}

export function pickUpdateOrder(order) {
  try {
    const pickedOrder = _.pick(order, updateOrderProps);
    pickedOrder.orderLines = pickedOrder.orderLines
      .filter(line => !line.deletedOrderLineId)
      .map(line => ({
        ..._.pick(line, orderLine),
        countryCode: line._embedded
          ? _.get(line, '_embedded.voucherCatalog.countryCode')
          : _.get(line, 'voucherCatalog.countryCode'),
      }));
    return pickedOrder;
  } catch (error) {
    return order;
  }
}

export function pickUpdateGRCOrder(order, updatedOrderValues) {
  try {
    const pickedOrder = _.pick({ ...order, ...updatedOrderValues }, updateOrderProps);
    pickedOrder.orderLines = pickedOrder.orderLines
      .filter(line => !line.deletedOrderLineId)
      .map(line => ({
        ..._.pick(line, grcOrderLine),
      }));
    return pickedOrder;
  } catch (error) {
    return order;
  }
}
