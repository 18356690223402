import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Route,
  Router,
  Switch,
  Redirect,
} from 'react-router-dom';
import { checkAuthentication as checkOktaAuthentication } from './okta';
import {
  VouchersPartnerRequester,
  VouchersSieAdmin,
  VouchersSieSuperAdmin,
} from '../../constants/userRoles.constants';
import LoadingWrapper from '../layouts/loadingWrapper';
import Header from '../common/Header';
import history from '../../history';
import trackingActions from '../../actions/trackingActions';
import ChangeRoute from '../common/RouteChange';

import NotAuthorized from '../error/NotAuthorized';
import hasPermissionCheck from '../../utils/accessControl/hasPermission';
import getFeature from '../../utils/accessControl/getFeature';
import componentMapping from './componentMapping';

export class AuthenticatedRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: null,
    };
  }

  componentDidMount() {
    this.checkAuthentication();
  }

  checkAuthentication() {
    const { authenticated } = this.state;
    return checkOktaAuthentication().then((isAuthenticated) => {
      if (isAuthenticated !== authenticated) {
        this.setState({ authenticated: isAuthenticated });
      }
      return isAuthenticated;
    });
  }

  renderRouteChange() {
    const { store, user } = this.props;
    return (
      <ChangeRoute actions={trackingActions} store={store} user={user} />
    );
  }

  render() {
    const { authenticated } = this.state;
    if (authenticated === null || !authenticated) {
      return <LoadingWrapper loading loadingClassName="app-auth-loading" />;
    }

    const {
      path, children, component, render, hasPermission, featureFlag, mustAcceptCurrentTerms,
      hasAcceptedCurrentTerms, needPGPSetup, viewedPGPSetupPage, hasCanceledPGPSetup, location,
    } = this.props;

    const tosPermissions = (mustAcceptCurrentTerms && !hasAcceptedCurrentTerms);

    if (component) {
      return (
        <div className="content">
          {(!mustAcceptCurrentTerms || hasAcceptedCurrentTerms) && <Header />}
          {hasPermission && featureFlag
            // User can access page. Check if user needs to accept the current Terms of Service
            ? (mustAcceptCurrentTerms && !hasAcceptedCurrentTerms && path !== '/tos' && path !== '/canceledtos')
              ? <Redirect to="/tos" /> // Redirect user to Terms of Service page
              : !tosPermissions && !viewedPGPSetupPage && !hasCanceledPGPSetup && needPGPSetup
                ? (
                  <Redirect
                    to={{
                      pathname: '/needpgpsetup',
                      state: { referrer: location.pathname },
                    }}
                  />
                ) // Redirect user to Step PGP key warning page
                : <Route path={path} component={component} /> // Send user where they want to go
            // User is not authorized to access page
            : (
              <LoadingWrapper minTimeForLoadDisplay={0} loadingClassName="app-auth-loading">
                <Router history={history}>
                  <Switch>
                    <Route path="*" exact component={NotAuthorized} />
                  </Switch>
                </Router>
              </LoadingWrapper>
            )
          }
          {this.renderRouteChange()}
        </div>
      );
    }
    if (render) {
      return <Route path={path} render={render} />;
    }
    if (children) {
      return (
        <Route path={path}>
          {children}
        </Route>
      );
    }
    return null;
  }
}

AuthenticatedRoute.propTypes = {
  /**
   * The path to route to.
   */
  path: PropTypes.string.isRequired,
  location: PropTypes.object,
  user: PropTypes.object,
  store: PropTypes.object,
  render: PropTypes.bool,
  hasPermission: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  featureFlag: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  mustAcceptCurrentTerms: PropTypes.bool.isRequired,
  hasAcceptedCurrentTerms: PropTypes.bool,
  needPGPSetup: PropTypes.bool,
  viewedPGPSetupPage: PropTypes.bool,
  hasCanceledPGPSetup: PropTypes.bool,
};

AuthenticatedRoute.defaultProps = {
  user: {},
  location: {},
  store: {},
  component: () => {},
  render: false,
  hasPermission: false,
  featureFlag: false,
  hasAcceptedCurrentTerms: false,
  needPGPSetup: false,
  viewedPGPSetupPage: false,
  hasCanceledPGPSetup: false,
};

export function mapStateToProps(state, ownProps) {
  const {
    user: {
      userId,
      mustAcceptCurrentTerms,
      hasAcceptedCurrentTerms,
      roleCode,
      hasActiveFingerprint,
      viewedPGPSetupPage,
      hasCanceledPGPSetup,
    },
  } = state;

  const { location: { pathname } } = ownProps;
  const action = componentMapping(pathname);
  const noPGPSetupRequired = localStorage.getItem(`needsPGPSetup-${userId}`) === 'true'
    || sessionStorage.getItem('canceledNeedsPGPSetup') === 'true';

  const needPGPSetup = (!hasActiveFingerprint && !noPGPSetupRequired && (
    roleCode === VouchersPartnerRequester.roleCode
    || roleCode === VouchersSieAdmin.roleCode
    || roleCode === VouchersSieSuperAdmin.roleCode
  ) && pathname !== '/needpgpsetup'
  );

  return {
    mustAcceptCurrentTerms,
    hasAcceptedCurrentTerms,
    needPGPSetup,
    viewedPGPSetupPage,
    hasCanceledPGPSetup,
    hasPermission: hasPermissionCheck(state, action),
    featureFlag: getFeature(action),
  };
}

export default connect(mapStateToProps)(AuthenticatedRoute);
