import React, { useState } from 'react';
import {
  TableRow,
  TableCell,
  Truncate,
  Icon,
  iconSizes,
} from '@partner-global-ui/components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { uniqBy } from 'lodash';
import SkuInfoSubRows from './SkuInfoSubRows';

export const SkuInfoRow = (props) => {
  const { t } = useTranslation();
  const {
    sku, deleteSku, canRemove, tableRef,
  } = props;
  const { skuId, status = '', skuAvailabilities } = sku;
  const uniqueSkuAvailabilities = uniqBy(skuAvailabilities, 'country.code');

  const statusCodes = {
    active: t('msg_codes_agreementStatus_active'),
    inactive: t('msg_codes_agreementStatus_inactive'),
  };

  let childrenCount = 0;
  childrenCount = uniqueSkuAvailabilities.length;
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpandState = () => {
    setIsExpanded(!isExpanded);
  };

  const renderRemoveButton = () => {
    if (!canRemove) {
      return null;
    }
    return (
      <div
        id="remove-wrap"
        data-testid="remove-wrap"
        type="button"
        role="button"
        tabIndex={0}
        className="remove-icon"
        onClick={() => deleteSku(skuId)}
        onKeyDown={() => {}}
      >
        <Icon id="trash-icon" className="remove-icon" size={iconSizes.Large}>
          ico-trashcan
        </Icon>
        <span
          id="remove-text"
          data-testid="remove-text"
          className="remove-text"
        >
          {t('msg_codes_cta_remove')}
        </span>
      </div>
    );
  };

  return (
    <TableRow
      pad
      className="sku-info-table-TableRow parent"
      id={`sku-info-table-TableRow-${skuId}`}
      key={skuId}
      onClick={toggleExpandState}
      expanded={isExpanded}
      expandable
      expandedContent={<SkuInfoSubRows skuAvailabilities={skuAvailabilities} />}
    >
      <TableCell sm={4} className="sku-info-table-sku-id">
        <Truncate
          id="skuId-truncate"
          content={skuId}
          height={20}
          anchor={tableRef}
        >
          {skuId}
        </Truncate>
      </TableCell>
      <TableCell id="sku-info-table-country" className="sku-info-table-country">
        {t('msg_codes_agreementType_multiple')}
        {`(${childrenCount})`}
      </TableCell>
      <TableCell
        id="sku-info-table-start-date"
        className="sku-info-table-start-date"
      >
        -
      </TableCell>
      <TableCell
        id="sku-info-table-end-date"
        sm={2}
        className="sku-info-table-end-date"
      >
        -
      </TableCell>
      <TableCell id="sku-info-table-status" className="sku-info-table-status">
        {statusCodes[status]}
      </TableCell>
      <TableCell
        id="sku-info-table-actions"
        className="sku-info-table-actions actions-column"
      >
        {renderRemoveButton()}
      </TableCell>
    </TableRow>
  );
};

SkuInfoRow.propTypes = {
  sku: PropTypes.object,
  deleteSku: PropTypes.func,
  canRemove: PropTypes.bool,
  tableRef: PropTypes.object,
};

SkuInfoRow.defaultProps = {
  sku: {},
  deleteSku: () => null,
  canRemove: false,
  tableRef: {},
};

export default SkuInfoRow;
