import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Collapsible,
  DataTable,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableCell,
  NoResults,
  Tooltip,
  Icon,
  Loader,
} from '@partner-global-ui/components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { usePermission } from '../../../utils/accessControl/hasPermission';
import roleKeys from '../../../utils/accessControl/roleKeys';
import orderLineErrors from '../../../constants/orderLineErrors.constants';
import countryMap from '../../../constants/countries.constants';
import OrderLineToolTip from './OrderLineToolTip';

function OrderContents({ order }) {
  const { t } = useTranslation();
  const user = useSelector(state => state.user);
  const [showAllLineKabobs, setShowAllLineKabobs] = useState(false);
  const canViewError = usePermission(roleKeys.order.viewThirdPartyLineErrors);
  const canDownload = usePermission(roleKeys.freeAllocationOrderContent.downloadLineItem);
  const canRetry = usePermission(roleKeys.freeAllocationOrderContent.retryLineItem);
  const canCancel = usePermission(roleKeys.freeAllocationOrderContent.cancelLineItem);
  const errorCode = 'FILE_GENERATION_ERROR';
  const tableRef = useRef();

  const { orderLines } = order;

  useEffect(() => {
    const shouldHaveKebobActions = orderLines.some(({
      cancelable,
      downloadable,
      retryable,
      fulfillmentStatus,
      activationStatus,
    }) => {
      const canCancelLine = canCancel && cancelable;
      const canDownloadLine = canDownload && downloadable;
      const canRetryLine = (
        retryable
        && canRetry
        && (fulfillmentStatus === 'Fulfillment Error' || activationStatus === 'Activation Error')
      );

      return canCancelLine || canDownloadLine || canRetryLine;
    });

    setShowAllLineKabobs(shouldHaveKebobActions);
  }, [orderLines]);

  // Due to how DataTable passes data to its children, line items cannot be in a dedicated component
  function renderTableRows() {
    return orderLines.map((line) => {
      const {
        activationStatus,
        autoCanceled,
        cancelable,
        canceled,
        deliveredVoucherCodeFilename,
        deliveryStatus,
        downloadable,
        fulfillmentStatus,
        npVoucherBatchId,
        npVoucherBatchStatus,
        quantity,
        exportErrorMessage,
        voucherCatalog: {
          countryAvailabilities,
          type,
          voucherName,
          voucherSku,
          voucherCatalogId,
          productId,
        },
        sftpVoucherDeliveryStatus,
        userVoucherDeliverStatus,
      } = line;

      const canRetryLine = canRetry && (fulfillmentStatus === 'Fulfillment Error' || activationStatus === 'Activation Error');
      const fulfillmentErrorCode = exportErrorMessage ? exportErrorMessage.substring(0, 4) : '';
      const isApproved = order
        && order.orderStatus === 'APPROVED_ORDER';

      const countries = countryAvailabilities.length === 0 ? ''
        : countryAvailabilities.length === 1 ? t(countryMap[countryAvailabilities[0].code].name)
          : t('msg_codes_num_countries_regions', { num: countryAvailabilities.length });

      const hideOrderLineToolTip = sftpVoucherDeliveryStatus === errorCode
        || userVoucherDeliverStatus === errorCode;

      function getTranslationId(status) {
        switch (status) {
          case 'Fulfilling':
            return t('msg_codes_orderListAdmin_fulfilling_label');
          case 'Fulfilled':
            return t('msg_codes_fulfillment_fulfilled');
          case 'Fulfillment Error':
            return t('msg_codes_fulfillment_fulfillmentError');
          case 'Delivered':
            return t('msg_codes_delivery_delivered');
          case 'Activated':
            return t('msg_codes_activation_activated');
          case 'Deactivated':
            return t('msg_codes_codes_status_deactivated');
          case 'Activation Error':
            return t('msg_codes_activationError');
          case 'File generation is in progress, please come back later to download.':
            return t('msg_codes_fileGen');
          case 'Pending Delivery':
            return t('msg_codes_pendingDelivery');
          case 'Inactive':
            return t('msg_codes_partnerStatus_inactive');
          default:
            return status;
        }
      }

      const renderErrorTooltip = () => {
        if (autoCanceled) {
          return (
            <Tooltip
              id="error_tooltip"
              position="top"
              content={t('msg_codes_orderCanceled')}
            >
              <Icon id="block-icon" className="block-icon">block</Icon>
            </Tooltip>
          );
        }

        const fulfillmentErrorReason = orderLineErrors.find((errors) => {
          return errors.code === fulfillmentErrorCode;
        });

        if (!fulfillmentErrorReason) {
          return null;
        }

        const fulfillmentErrorMessage = fulfillmentErrorReason
          ? t(fulfillmentErrorReason.error)
          : '';

        return canViewError && (
          <Tooltip
            id="warning-tooltip"
            position="top"
            content={fulfillmentErrorMessage}
          >
            <Icon id="warning-icon" className="block-icon">warning</Icon>
          </Tooltip>
        );
      };

      const renderCountryTooltip = countryAvailabilities.length > 1 && (
        <Tooltip
          id="list-tooltip"
          position="top"
          content={countryAvailabilities.map(c => c.name).join(', ')}
        >
          <Icon id="list-icon" className="list-icon" size={20}>list</Icon>
        </Tooltip>
      );

      const fulfillmentIcon = deliveredVoucherCodeFilename && (
        <Tooltip
          id="description-tooltip"
          position="top"
          content={deliveredVoucherCodeFilename}
          strategy="fixed"
        >
          <Icon
            id="description-icon"
            className="description-icon"
            size={20}
          >
            description
          </Icon>
        </Tooltip>
      );

      const displayTypeName = {
        PRODUCT: t('msg_codes_voucherType_product_lowerCase'),
        MONEY: t('msg_codes_voucherType_money_lowerCase'),
        PSPLUS: t('msg_codes_voucherType_psPlus'),
        PSNOW: t('msg_codes_voucherType_psNow_lowerCase'),
      };

      const getLineClasses = () => {
        return [
          'order-line-3p',
          npVoucherBatchStatus === 'CANCELLATION_COMPLETE'
            || npVoucherBatchStatus === 'CANCELLATION_ERROR'
            ? 'isCancelled'
            : '',
          autoCanceled ? 'isLineAutoCanceled' : '',
        ]
          .join(' ')
          .trim()
          .replace(/\s+/g, ' ');
      };

      const displayFulfillmentIcon = () => {
        const isCanceling = canceled
          && npVoucherBatchStatus !== 'CANCELLATION_COMPLETE'
          && npVoucherBatchStatus !== 'CANCELLATION_ERROR';

        if (fulfillmentStatus === 'Fulfilling'
          || isCanceling
        ) {
          return <Loader id="processing-fulfillment" size="sm" />;
        }

        return null;
      };

      const displayDeliveryIcon = () => {
        if (fulfillmentStatus === 'Fulfilled') {
          return fulfillmentIcon;
        }

        return null;
      };

      const renderKabob = () => {
        return (
          <OrderLineToolTip
            line={line}
            order={order}
            canCancel={cancelable && canCancel}
            canDownload={downloadable && canDownload}
            canActivate={false}
            canRetry={canRetryLine}
            role={user.roleCode}
            isApproved={isApproved}
            name="kabob"
          />
        );
      };

      return (
        <TableRow
          className={getLineClasses()}
          id="table-row"
        >
          <TableCell
            id="codeProductName"
            className={`${getLineClasses()} codeProductName`}
          >
            <Link
              data-testid="codeProductNameLink"
              to={`/codeproduct/${voucherCatalogId}`}
              rel="noopener noreferrer"
              className="name-link-3p"
            >
              {voucherName}
            </Link>
            {renderErrorTooltip()}
          </TableCell>
          <TableCell name="skuId" id="skuId" className={`${getLineClasses()} skuId`}>
            {!voucherSku ? t('msg_codes_codes_status_notAvailable') : voucherSku}
          </TableCell>
          <TableCell name="productId" id="productId" className={`${getLineClasses()} productId`}>
            {!productId ? t('msg_codes_codes_status_notAvailable') : productId}
          </TableCell>
          <TableCell name="productType" id="productType" className={`${getLineClasses()} productType`}>
            {!type ? t('msg_codes_codes_status_notAvailable') : displayTypeName[type]}
          </TableCell>
          <TableCell name="country" id="country" className={`${getLineClasses()} country`}>
            {countries} {renderCountryTooltip}
          </TableCell>
          <TableCell name="quantity" id="quantity" className={`${getLineClasses()} quantity`}>
            {!quantity ? t('msg_codes_codes_status_notAvailable') : quantity}
          </TableCell>
          <TableCell name="batchId" id="batchId" className={`${getLineClasses()} batchId`}>
            {!npVoucherBatchId ? t('msg_codes_codes_status_notAvailable') : npVoucherBatchId}
          </TableCell>
          <TableCell name="batchFileName" id="batchFileName" className={`${getLineClasses()} batchFileName`}>
            {!deliveredVoucherCodeFilename ? t('msg_codes_codes_status_notAvailable') : deliveredVoucherCodeFilename}
          </TableCell>
          <TableCell name="fulfillment" id="fulfillment" className={`${getLineClasses()} fulfillment`}>
            {getTranslationId(fulfillmentStatus)} {displayFulfillmentIcon()}
          </TableCell>
          <TableCell name="delivery" id="delivery" className={`${getLineClasses()} delivery`}>
            {getTranslationId(deliveryStatus)} {displayDeliveryIcon()}
          </TableCell>
          <TableCell name="activation" id="activation" className={`${getLineClasses()} activation`}>
            {getTranslationId(activationStatus)}
            {' '}
            {(activationStatus === 'Activating' || activationStatus === 'Cancelling code') && (
              <Loader id="processing-activation" size="sm" />
            )}
          </TableCell>
          {
            showAllLineKabobs && !hideOrderLineToolTip
              ? (
                <TableCell
                  name="kabob"
                  id="kabob"
                  className={`${getLineClasses()} kabob`}
                >
                  {renderKabob()}
                </TableCell>
              ) : <></>
          }
        </TableRow>
      );
    });
  }

  const tableHeaders = [
    { label: t('msg_codes_codes_codeProduct'), name: 'codeProduct' },
    { label: t('msg_codes_orderList_skuid'), name: 'skuid' },
    { label: t('msg_codes_codes_productID'), name: 'productId' },
    { label: t('msg_codes_codes_productType'), name: 'productType' },
    { label: t('msg_codes_orderList_countryHeader'), name: 'country' },
    { label: t('msg_codes_codes_quantity'), name: 'quantity' },
    { label: t('msg_codes_codes_batchID'), name: 'batchId' },
    { label: t('msg_codes_orderDetailsAdmin_batchFileName_label'), name: 'batchFileName' },
    { label: t('msg_codes_orderDetailsAdmin_fulfillment_label'), name: 'fulfillmentLabel' },
    { label: t('msg_codes_orderDetails_delivery'), name: 'deliveryLabel' },
    { label: t('msg_codes_orderDetailsAdmin_Activation_label'), name: 'activationLabel' },
    ...(showAllLineKabobs ? [''] : []),
  ];

  const renderHeaderCells = () => {
    return tableHeaders.map(({ label, name }) => {
      return (
        <TableHeaderCell
          id={name}
          key={name}
          value={name}
          className={name}
        >
          {label}
        </TableHeaderCell>
      );
    });
  };

  const columnTemplate = showAllLineKabobs
    ? '200px 200px 200px 129px 120px 80px 150px 150px 150px 150px 150px 60px'
    : '200px 200px 200px 129px 120px 80px 150px 150px 157px 157px 157px';

  return (
    <div data-testid="order-contents-section" className="order-contents-section">
      <Collapsible
        id="order-contents-collapsible"
        heading={t('msg_codes_orderContents_header')}
        defaultIsOpen
      >
        <DataTable
          id="order-contents-table"
          data-testid="order-contents-table"
          columnTemplate={columnTemplate}
          scrollHeight={400}
          maxHeight={400}
          isFirstColumnSticky
          isLastColumnSticky={showAllLineKabobs}
          ref={tableRef}
        >
          {order.orderLines.length ? (
            <TableHeader id="order-contents-table-header">
              {renderHeaderCells()}
            </TableHeader>
          ) : <></>}
          {
            order.orderLines.length
              ? renderTableRows()
              : (
                <NoResults
                  id="no-results"
                  title={t('msg_codes_nothing_here_yet')}
                  message={t('msg_codes_orderContents_noneFound')}
                />
              )
          }
        </DataTable>
      </Collapsible>
    </div>
  );
}

OrderContents.propTypes = {
  order: PropTypes.object,
};

OrderContents.defaultProps = {
  order: {},
};

export default OrderContents;
