function filtersBuilder(filters) {
  const filterParams = Object.keys(filters).reduce((params, filterParam) => {
    return filters[filterParam].reduce((subParams, subFilter) => {
      return `${subParams}&${filterParam}=${subFilter.value}`;
    }, params);
  }, '');
  return filterParams;
}

export default filtersBuilder;
