import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { startCase, toLower } from 'lodash';
import {
  Layout,
  Flex,
  Search,
} from '@partner-global-ui/components';
import sortBy from 'lodash/sortBy';

import SecondaryNav from '../SecondaryNav/SecondaryNav';
import { allSearchOption } from '../../../constants/searchOptions.constants';
import './SearchHeader.scss';

export const sanitizeSearchTerm = (searchTerm) => {
  if (!searchTerm) {
    return undefined;
  }

  // Decode any encoded URI characters
  const decodedTerm = decodeURIComponent(searchTerm);
  // Replace URL's formatting of using +'s for spaces
  const replacedSearchTerm = decodedTerm.replace(/\+/g, ' ');

  return replacedSearchTerm;
};

export default function SearchHeader({
  name,
  title,
  subTitle,
  options,
  clearIcon,
  placeholder,
  changeSearch,
  isOrdersPage,
  canSearch,
  hideSecondaryNav,
  term,
}) {
  const { t } = useTranslation();

  const { cart } = useSelector(state => state.cartPage);
  const translationOptions = sortBy(options.reduce(
    (acc, obj) => {
      return [
        ...acc,
        {
          value: obj.value,
          label: startCase(toLower(t(obj.label))),
        },
      ];
    }, [],
  ), 'label');

  /* handle string or element subTitles */
  function renderSubtitle() {
    if (typeof subTitle === 'string') {
      return subTitle !== '' && <p data-testid="test-subtitle">{subTitle}</p>;
    }

    return subTitle;
  }

  const classes = classNames(
    'header',
    {
      'no-secondary-nav': hideSecondaryNav,
    },
  );

  return (
    <Flex className={classes}>
      {hideSecondaryNav
        ? ''
        : (
          <Layout className="search-header-secondary-nav">
            <Flex colSpan="4" offset="8">
              <SecondaryNav isOrdersPage={isOrdersPage} cartSize={cart.length} />
            </Flex>
          </Layout>
        )
      }
      <Layout className="search-header-container">
        <Flex colSpan="6">
          <div className="search-header-title-container" data-testid="search-header-container">
            <h2
              id={`${name}-header-search`}
              name={`${name}-header-search`}
              data-testid="test-title"
            >
              {title}
            </h2>
            {renderSubtitle()}
          </div>
        </Flex>
        <Flex colSpan="6">
          <div className="select-container">
            {canSearch && (
              <Search
                categories={translationOptions}
                useClearIcon={clearIcon}
                placeholder={placeholder}
                search={changeSearch}
                allCategoriesText={t(allSearchOption.label)}
                onClearSearch={changeSearch}
                searchDefaultValue={term}
              />
            )}
          </div>
        </Flex>
      </Layout>
    </Flex>
  );
}

SearchHeader.propTypes = {
  changeSearch: PropTypes.func,
  title: PropTypes.string,
  name: PropTypes.string,
  subTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  placeholder: PropTypes.string,
  clearIcon: PropTypes.bool,
  isOrdersPage: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.exact({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  canSearch: PropTypes.bool,
  hideSecondaryNav: PropTypes.bool,
  term: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

SearchHeader.defaultProps = {
  changeSearch: () => { },
  title: '',
  name: '',
  subTitle: '',
  placeholder: 'Type Something...',
  clearIcon: false,
  isOrdersPage: false,
  options: [
    { label: '', value: '' },
  ],
  canSearch: false,
  hideSecondaryNav: false,
  term: '',
};
