import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SpinnerLoader from './spinnerLoader';

class LoadingWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasTimeout: false,
    };
    this.timeoutHandler = null;
  }

  componentDidMount() {
    const { minTimeForLoadDisplay } = this.props;
    if (minTimeForLoadDisplay > 0) {
      this.timeoutHandler = setTimeout(() => {
        this.setState({ hasTimeout: false });
      }, minTimeForLoadDisplay);

      this.setState({ hasTimeout: true });
    }
  }

  componentWillUnmount() {
    if (this.timeoutHandler) {
      clearTimeout(this.timeoutHandler);
    }
  }

  render() {
    const {
      children, loading, className, loadingClassName, label, width,
    } = this.props;
    const { hasTimeout } = this.state;

    const showSpinner = loading || hasTimeout;

    if (showSpinner) {
      return (
        <div className={loadingClassName}>
          <SpinnerLoader label={label} width={width} />
        </div>
      );
    }
    return (
      <div className={className}>
        {children}
      </div>
    );
  }
}

LoadingWrapper.propTypes = {
  /**
   * child nodes ready to be displayed or not
   */
  loading: PropTypes.bool,
  /**
   * if > 0 adds a timeout to guarantee a minimum time to
   * display the spinner, under 300ms it would be just a flicker
   */
  minTimeForLoadDisplay: PropTypes.number,
  /**
   * The CSS class for the div to wrap the children when not loading.
   */
  className: PropTypes.string,
  /**
   * The CSS class for the div to wrap the spinner.
   */
  loadingClassName: PropTypes.string,
  /**
   * The label to use if needed.
   */
  label: PropTypes.string,
  /**
   * The width of the loading icon.
   */
  width: PropTypes.number,
};

LoadingWrapper.defaultProps = {
  loading: false,
  minTimeForLoadDisplay: 400,
  className: '',
  loadingClassName: '',
  label: '',
  width: 100,
};

export default LoadingWrapper;
